import React, { useEffect, useState } from 'react';
import './findText.scss'
import { connect } from 'react-redux';
import { ADD_FOUND_WORDS, SET_CASE_MATCH, SET_FOUND_WORDS, SET_SEARCH_INDEX, SET_SEARCH_TEXT } from '../../sagas/constants';
import {
    Input,
  } from "@material-ui/core";
import AmberCheckbox from '../inputs/AmberCheckbox';
import AmberPrimaryButton from '../buttons/AmberPrimaryButton';

const mapStateToProps = ({ findText }) => ({ findText });

function FindText({ 
    transcriptJson,
    editor,
    onSeekAndCenterAudio,
    dispatch,
    findText: {
        isCheckedCaseMatch,
        searchText,
        foundStartWords,
        searchIndex,
    }}) {
  
  const [replaceWithString, setReplaceWithString] = useState('');

  useEffect(() => {
    if (searchIndex !== undefined) {
        seekToSeachWord();
    }
  }, [searchIndex])

  useEffect(() => {
    return () => {
        dispatch({type: SET_FOUND_WORDS, value: []});
        dispatch({type: SET_SEARCH_INDEX, value: undefined});
    }
  }, [])

  const handleCheckboxChange = () => {
    dispatch({type: SET_CASE_MATCH, value: !isCheckedCaseMatch});
    dispatch({type: SET_FOUND_WORDS, value: []});
    dispatch({type: SET_SEARCH_INDEX, value: undefined});
  };

  const handleSearchInputChange = (event) => {
    dispatch({type: SET_SEARCH_TEXT, value: event.target.value});
    dispatch({type: SET_FOUND_WORDS, value: []});
    dispatch({type: SET_SEARCH_INDEX, value: undefined});
  };

  const  handleSearch = () => {
    let isSearchInSegment = false;
    dispatch({type: SET_FOUND_WORDS, value: []});
    dispatch({type: SET_SEARCH_INDEX, value: undefined});
    [...transcriptJson.segments].forEach((segment) => {
        const segmentText = [...segment.words].map((word) => word?.text).join(' ');
        isSearchInSegment = isCheckedCaseMatch ?
            segmentText.includes(searchText):
            segmentText.toLowerCase().includes(searchText.toLowerCase());
        if (isSearchInSegment) {
            [...segment.words].forEach((word, index) => {
                const foundWord =  searchText.split(' ').every((searchWord, searchIdx) => {
                    return isCheckedCaseMatch ?
                        segment.words[index + searchIdx]?.text === searchWord:
                        segment.words[index + searchIdx]?.text.toLowerCase() === searchWord.toLowerCase();
                })
                if (foundWord){
                    dispatch({type: ADD_FOUND_WORDS, value: word});
                }
            })
        }
    });
    dispatch({type: SET_SEARCH_INDEX, value: 0});
  };
  
  const handleSearchPrevious = () => {
    const previousIndex = searchIndex > 0 ? searchIndex - 1 : foundStartWords.length - 1
    dispatch({type: SET_SEARCH_INDEX, value: previousIndex});
  }

  const handleSearchNext = () => {
    dispatch({type: SET_SEARCH_INDEX, value: (searchIndex + 1) % foundStartWords.length});
  }

  const seekToSeachWord = () => {
    if (foundStartWords.length > 0) {
        editor.setAudioPlayerTime(foundStartWords[searchIndex].start, true, false);
        onSeekAndCenterAudio(foundStartWords[searchIndex].start);
        // Need to wait for seek to render new content before scrolling down to be able to see
        // what is searched for in text field.
        setTimeout(() => {
            window.scrollTo(window.scrollX, window.scrollY + 30);
        }, 101)
    }
  }

  const handleReplaceAll = () => {
    if (editor) {
      editor.findReplace(searchText, replaceWithString, { caseSensitive: isCheckedCaseMatch })
    }
  }

  const handleReplaceInputChange = (event) => {
    setReplaceWithString(event.target.value);
  }

  return (
    <div className='findWrapper'>
      <Input
        type="text"
        value={searchText}
        onChange={handleSearchInputChange}
        inputProps={{ maxLength: 64 }}
        autoFocus
      />
      <div className='buttonWrapper' >
        <AmberCheckbox label='Match case' checked={isCheckedCaseMatch} handleChange={handleCheckboxChange} />
        <AmberPrimaryButton disabled={searchText === ""} onClick={handleSearch} text='Find' />
        <AmberPrimaryButton disabled={foundStartWords.length < 2} onClick={handleSearchPrevious} text='Previous' />
        <AmberPrimaryButton disabled={foundStartWords.length < 2} onClick={handleSearchNext} text='Next' />
      </div>
      <div>Replace with</div>
      <Input
        type="text"
        value={replaceWithString}
        onChange={handleReplaceInputChange}
        inputProps={{ maxLength: 64 }}
      />
      <div className='buttonWrapper'>
        <AmberPrimaryButton disabled={searchText === "" || replaceWithString === ""} onClick={handleReplaceAll} text='Replace all' />
      </div>
    </div>
  );
}

export default connect(mapStateToProps)(FindText);