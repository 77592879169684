import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, makeStyles } from '@material-ui/core';
import { getSpellingSuggestionForListOfWords } from '../../../sagas/qualityCheck';
import SpellingPanel from './SpellingPanel';
import {
  PRE_QC_ISSUE_TYPE_CLEANREAD,
  PRE_QC_ISSUE_TYPE_FORMATTING,
  PRE_QC_ISSUE_TYPE_GRAMMAR,
  PRE_QC_ISSUE_TYPE_INAUDIBLE,
  PRE_QC_ISSUE_TYPE_SPELLING,
} from '../../../sagas/constants';
import GrammarPanel from './GrammarPanel';
import CleanreadPanel from './CleanreadPanel';
import InaudiblePanel from './InaudiblePanel';
import {
  getIssuesByType,
  getIssuesDetails,
  getWordsArrays,
  mapSuggestionsToWords,
} from './helpers';
import FormattingPanel from './FormattingPanel';
import { trackSentryErrorByTag } from '../../../sentry/log';

const useStyles = makeStyles((theme) => ({
  overflowBox: {
    overflowX: 'hidden',
    overflowY: 'scroll',
    overflowWrap: 'anywhere',
    maxHeight: 'calc(100vh - 295px)',
  },
}));
export default function PreQCFilledPanel(props) {
  const {
    translate,
    preQCIssues,
    language,
    setLoadingQC,
    handleOpenReportDialog,
  } = props;

  const classes = useStyles();

  const [loadingSpelling, setLoadingSpelling] = useState(false);
  const [preQCIssuesDetails, setPreQCIssuesDetails] = useState({
    grammar: [],
    cleanread: [],
    inaudible: [],
    formatting: [],
  });

  // Separated from the rest because takes time to load
  const [spellingDetails, setSpellingDetails] = useState([]);

  // Gets the spelling suggestions for all the existing spelling issues
  const getSpellingSuggestions = async () => {
    if (preQCIssues && preQCIssues.length > 0 && language !== '') {
      // Isolate spelling issues
      const spellingIssues = getIssuesByType(
        preQCIssues,
        PRE_QC_ISSUE_TYPE_SPELLING
      );

      // If none found, do nothing
      if (spellingIssues.length === 0) {
        return [];
      }

      // Get words and words with timestamps arrays
      const { words, wordsWithTimestamps } = getWordsArrays(spellingIssues);
      let result = [];

      setLoadingSpelling(true);

      try {
        const response = await getSpellingSuggestionForListOfWords(
          language,
          words
        );
        if (response && response.data) {
          const { suggestions } = response.data;
          // Add suggestions to the corresponding words
          result = mapSuggestionsToWords(suggestions, wordsWithTimestamps);
        }
      } catch (error) {
      } finally {
        setLoadingQC(false);
        return result;
      }
    }
  };

  // Initializes all the issues
  const initializeIssuesDetails = async () => {
    if (!preQCIssues || preQCIssues.length === 0) {
      return;
    }
    try {
      const grammar = getIssuesDetails(preQCIssues, PRE_QC_ISSUE_TYPE_GRAMMAR);
      const cleanread = getIssuesDetails(
        preQCIssues,
        PRE_QC_ISSUE_TYPE_CLEANREAD
      );
      const inaudible = getIssuesDetails(
        preQCIssues,
        PRE_QC_ISSUE_TYPE_INAUDIBLE
      );
      const formatting = getIssuesDetails(
        preQCIssues,
        PRE_QC_ISSUE_TYPE_FORMATTING
      );
      setPreQCIssuesDetails({
        ...preQCIssuesDetails,
        grammar,
        cleanread,
        inaudible,
        formatting,
      });
      const spelling = await getSpellingSuggestions();
      setSpellingDetails(spelling);
    } catch (error) {
      trackSentryErrorByTag(error, 'pre-qc-fill-categories')
    }
    finally {
      setLoadingSpelling(false);
    }
  };

  useEffect(() => {
    initializeIssuesDetails();
  }, [preQCIssues]);

  const hasGrammarIssues = () => preQCIssuesDetails?.grammar?.length > 0;

  const hasCleanreadIssues = () => preQCIssuesDetails?.cleanread?.length > 0;

  const hasInaudibleIssues = () => preQCIssuesDetails?.inaudible?.length > 0;

  const hasFormattingIssues = () => preQCIssuesDetails?.formatting?.length > 0;

  return (
    <Box
      className={classes.overflowBox}
      data-testid="pre-qc-box-filled-panel"
    >
      {/* Spelling panel */}
        <SpellingPanel
          spellingIssues={spellingDetails}
          translate={translate}
          loading={loadingSpelling}
          handleOpenReportDialog={handleOpenReportDialog}
        />

      {/* Grammar panel */}
      {hasGrammarIssues() && (
        <GrammarPanel
          grammarIssues={preQCIssuesDetails.grammar}
          translate={translate}
        />
      )}

      {/* Cleanread panel */}
      {hasCleanreadIssues() && (
        <CleanreadPanel
          cleanreadIssues={preQCIssuesDetails.cleanread}
          translate={translate}
        />
      )}

      {/* Inaudible panel */}
      {hasInaudibleIssues() && (
        <InaudiblePanel
          inaudibleIssues={preQCIssuesDetails.inaudible}
          translate={translate}
        />
      )}

      {/* Formatting panel */}
      {hasFormattingIssues() && (
        <FormattingPanel
          formattingIssues={preQCIssuesDetails.formatting}
          translate={translate}
        />
      )}
    </Box>
  );
}

PreQCFilledPanel.propTypes = {
  translate: PropTypes.func.isRequired,
  preQCIssues: PropTypes.arrayOf(
    PropTypes.shape({
      end_time: PropTypes.number.isRequired,
      end_word_index: PropTypes.number.isRequired,
      issue_severity: PropTypes.string.isRequired,
      issue_type: PropTypes.string.isRequired,
      issue_type_detail: PropTypes.string,
      original_text: PropTypes.string.isRequired,
      segment_index: PropTypes.number.isRequired,
      start_time: PropTypes.number.isRequired,
      start_word_index: PropTypes.number.isRequired,
      suggestion: PropTypes.string.isRequired,
    })
  ).isRequired,
  language: PropTypes.string.isRequired,
  setLoadingQC: PropTypes.func.isRequired,
  handleOpenReportDialog: PropTypes.func.isRequired,
};
