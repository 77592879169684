import React from "react";
import PropTypes from "prop-types";
import { Box, Dialog, DialogTitle, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import PreQCDialogContent from "./preQCDialogContent";
import PreQCDialogActions from "./preQCDialogActions";

export default function PreQCDialog(props) {
  const {
    open,
    translate,
    issues,
    handleClose,
    handleViewAndFix,
    handleSubmit,
    confirmation,
    setConfirmation,
  } = props;

  const handleSetConfirmation = (state) => {
    setConfirmation(state);
  };

  return (
    <Dialog
      open={open}
      disableEscapeKeyDown
      maxWidth="sm"
      fullWidth
      data-testid="pre-qc-dialog-issues"
    >
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          {confirmation
            ? translate("EDITOR.PRE_QC.confirmationTitle")
            : translate("EDITOR.PRE_QC.dialogTitle")}
          <IconButton onClick={handleClose} data-testid="pre-qc-dialog-btn-close">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>

      <PreQCDialogContent
        issues={issues}
        translate={translate}
        confirmation={confirmation}
      />

      <PreQCDialogActions
        translate={translate}
        handleClose={handleClose}
        handleViewAndFix={handleViewAndFix}
        confirmation={confirmation}
        setConfirmation={handleSetConfirmation}
        handleSubmit={handleSubmit}
      />
    </Dialog>
  );
}

PreQCDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  translate: PropTypes.func.isRequired,
  issues: PropTypes.shape({
    cleanread: PropTypes.number,
    grammar: PropTypes.number,
    inaudible: PropTypes.number,
    spelling: PropTypes.number,
  }),
  handleClose: PropTypes.func.isRequired,
  handleViewAndFix: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  confirmation: PropTypes.bool.isRequired,
  setConfirmation: PropTypes.func.isRequired,
};

PreQCDialog.defaultProps = {
  issues: {},
};
