export const es = {
  locale: "es",
  messages: {
    MISC: {
      formErrRequired: "Se requiere un valor",
      formErrEmail: "El correo electrónico debe ser válido",
      formHintYes: "Si",
      formHintQuestion: "Utiliza {{ what }}?",
      signedInAs: "Has iniciado sesión como",
      logout: "Cerrar sesión",
      youOwner: "Tú",
      closeDialogBtn: "Cerrar",
      ariaCloseDialog: "Cerrar diálogo",
      clearSearch: "Borrar búsqueda",
      serverErrorDefaultToastMsg: "Error del servidor",
      ienoticeTitle: "Navegador no compatible",
      ienoticeTxt:
        "Internet Explorer no es compatible de momento. Por favor, utilize otro navegador, como Google Chrome o Microsoft Edge",
      ienoticeDismiss: "Entendí",
      requestFullTranscriptBodyDialog:
        "Si desea que su texto sea perfeccionado por nuestros transcriptores, cárguelo de nuevo y elija 'perfecto' como tipo de transcripción.",
      requestFullTranscriptTitle: "Obten texto 100% preciso",
      requestFullTranscriptBody:
        "Solicite cotización para una transcripción perfecta por nuestro equipo",
    },
    CONFIRM_SUBMIT: {
      title: "¿Está seguro de que desea enviar el archivo?",
      checkbox1:
        "He revisado las notas adicionales y he implementado los requisitos específicos del cliente si fueron solicitados.",
      checkbox2:
        "Se ha revisado toda la salida ASR del archivo con el corrector ortográfico y gramatical.",
      checkbox3: "La transcripción se ha creado de acuerdo con las pautas.",
      back: "Volver al archivo",
      submit: "Enviar archivo para revisión",
    },
    TRANSCRIPT_ERROR: {
      message:
        "Ya no tienes acceso a este archivo. El archivo ha sido reasignado. Si tienes alguna pregunta, contacta con Operaciones.",
      button: "Volver al dashboard",
    },
    NAV: {
      titleAccount: "Cuenta",
      titleAccountNew: "Añadir usuario",
      titleAccountMy: "Mi cuenta",
      titleBilling: "Facturación",
      titleInvoices: "Facturas",
      titleCheckout: "Pago",
      titleCoupon: "Canjear cupón",
      titleInvite: "Invitar amigos",
      titleLogin: "Acceso",
      titlePasswordReset: "Restablecer contraseña",
      titleEmailvalidate: "Validar email",
      titlePlan: "Precios",
      titleCosts: "Costes",
      titleRecords: "Tus Ambers",
      titleRecordsTooltip: "Volver al dashboard",
      titleRegister: "Regístrate",
      titleTransactions: "Transcripciones",
      titleUploads: "Uploads",
      titleDashboard: "Dashboard",
      titleManageUsers: "Gestionar usuarios",
      titleAddUser: "Añadir usuarios",
      titleManageTranscriptions: "Gestionar transcripciones",
      tooltipAdmin: "Administrador",
      titleAdminUser: "Gestionar usuarios",
      tooltipLanguage: "Cambiar idioma",
      newBadge: "nuevo",
      help: "Ayuda",
      currentPlanFallback: "Plan completo",
      currentPlanTooltip: "Plan actual",
    },
    LANGUAGES: {
      en: "Inglés",
      nl: "Holandés",
      de: "Alemán",
      fr: "Francés",
      es: "Espanõl",
      it: "Italiano",
      pt: "Portugués",
    },
    ACCOUNT: {
      titleForm: "Detalles de la cuenta",
      username: "Nombre de usuario",
      email: "Email",
      firstName: "Nombre",
      lastName: "Apellido",
      company: "Empresa",
      pwd: "Contraseña",
      newPwd: "Nueva Contraseña",
      confirmPwd: "Confirmar Contraseña",
      changePwd: "Cambiar contraseña",
      createPwd: "Crear contraseña",
      msgUsernameMaxlength:
        "El nombre tiene que tener menos de {{ quantity }} caracteres.",
      msgPwdLength:
        "La contraseña debe tener como mínimo {{ quantity }} caracteres.",
      msgPwdPattern:
        "La contraseña debe contener como mínimo un número, una letra mayúscua, una letra minúscula y un símbolo.",
      msgPwdConfirm: "Confirma la nueva contraseña",
      msgPwdMismatch: "Debe coincidir con la entrada anterior",
      loading: "Cargando detalles...",
      update: "Actualizar datos",
      updating: "Guardando datos...",
      save: "Guardar usuario",
      saving: "Creando usuario...",
    },
    ADMIN: {
      add: {
        donateSeconds: "Donar segundos",
      },
      dashboard: {
        loading: "Cargando dashboard...",
        noResults: "No hay elementos disponibles en el dashboard",
      },
      transactions: {
        id: "ID",
        owner: "Titular",
        seconds: "Segundos",
        date: "Fecha",
        noId: "Ningún ID especificado",
        loading: "Cargando transacciones...",
        noResults: "No hay transacciones para mostrar",
        results: "Transacciones recuperadas:",
      },
      transcriptions: {
        created: "Creado",
        id: "ID",
        name: "Nombre",
        user: "Usuario",
        status: "Estado",
        length: "Extensión",
        type: "Tipo",
        loading: "Cargando Ambers...",
        noResults: "No hay Ambers que mostrar",
        results: "Ambers recuperadas:",
      },
      users: {
        created: "Creado",
        username: "Nombre del usuario",
        fullname: "Nombre completo",
        status: "Estado",
        minutes: "Tiempo de audio",
        pause: "Pausar usuario",
        activate: "Activar usuario",
        delete: "Eliminar usuario",
        subscribed: "Suscrito",
        loading: "Cargando usuarios...",
        noResults: "No hay usuarios que mostrar",
        results: "Usuarios recuperados:",
        deleteConfirm: "¿Estás seguros de que quieres '{{ name }}'?",
        deleteOk: "Si",
        deleteCancel: "Cancelar",
        search: "Buscar usuarios",
      },
      user: {
        navBackToUsers: "Todos los usuarios",
        navEdit: "Perfil",
        navTranscriptions: "Transcripciones",
        navTransactions: "Transacciones",
        navDonate: "Donar",
        fullName: "Nombre completo",
        availableSeconds: "Tiempo disponible",
        created: "Creado en",
        isSubscribed: "Suscripción actual",
        isntSubscribed: "Ningún suscripción",
        status: "Estado",
        userType: "Tipo",
        language: "Idioma preferido",
        makeAdmin: "Capacidades de administrador",
        cancelSubscription: "Cancelar suscripción",
        cancellingSubscription: "Cancelando...",
      },
      donate: {
        title: "Compartir horas",
        username: "usuario del destinatario",
        seconds: "Número de segundos",
        secondsFormatted: "Horas : minutos : segundos",
        comment: "Mensaje para el destinatario",
        submit: "Enviar",
        submitting: "Enviando...",
      },
      sendvalidationemail: {
        submit: "Enviar email de validación",
        submitting: "Enviando",
      },
    },
    BILLING: {
      titleForm: "Detalles de facturación",
      firstName: "Nombre",
      lastName: "Apellido",
      email: "Email",
      phone: "Número telefónico",
      company: "Empresa",
      vatNumber: "Número de IVA",
      address: "Dirección",
      address2: "Dirección 2",
      city: "Ciudad",
      country: "País",
      zipCode: "Código postal",
      state: "Estado",
      loading: "Cargando detalles...",
      save: "Guardar detalles",
      saving: "Guardando detalles...",
    },
    CHECKOUT: {
      choose: "1. Elegir plan",
      setup: "2. Configurar pago",
      confirm: "3. Confirmación",
      loadingStripe: "Cargando stripe...",
      titleCard: "Detalles de la tarjeta",
      titleBilling: "Detalles de facturación",
      titleSummary: "Resumen del pedido",
      subscribe: "Suscripción al plan",
      subscribing: "Abonando...",
      info: "Amberscript acepta las siguientes tarjetas de crédito y débito. Los pagos son manejados de manera segura por Stripe.",
      labelNameOnCard: "Nombre en la tarjeta",
      labelCard: "Tarjeta de crédito o débito",
      termsDisclaimer: "Al continuar, aceptas los ",
      termsDisclaimerLinkTxt: "Términos y Condiciones de Amberscript",
    },
    SUMMARY: {
      plan: "Plan:",
      cost: "Precio:",
      beyond: "Además de",
      hrs: "hrs",
      hour: "hora",
      billingCycle: "Ciclo de facturación:",
      billingInterval: "mensualmente",
      total: "Total:",
      loading: "Cargando resumen...",
      error: "No se pudo cargar el resumen :(",
    },
    COSTS: {
      titlePlan: "Plan actual",
      titleCosts: "Resumen",
      titleInvoices: "Lista de facturas",
      noResultsPlan: "Obtener plan completo",
      noResultsCosts: "No tienes costes para este mes",
      noResultsInvoices: "Todavía no hay facturas.",
      loadingSummary: "Cargando resumen...",
      loadingInvoices: "Cargando facturas...",
      summary:
        "Este mes ha utilizado {{ time }} con un coste total de {{ cost }} euro.",
      remainder: "Con tu plan {{ planName }} pagas {{ price }} por hora",
      remainderB: "tienes {{ num }} horas gratis incluidas",
      remainderC1: "y un coste fijo de {{ cost }} por {{ interval }}.",
      remainderC2: "y sin costes fijos.",
      INVOICES: {
        invoiceNr: "Nr",
        created: "Creado",
        description: "Descripción",
        monthPeriod: "Mes",
        yearPeriod: "Año",
        nettoAmount: "Neto",
        vatAmount: "IVA",
      },
    },
    COUPON: {
      title: "Canjear un cupón",
      inputLabel: "Código del cupón",
      submit: "Enviar",
      submitting: "Enviando...",
      cancel: "Cancelar",
      invalidCouponCode: "Código del cupón inválido",
    },
    EDITOR: {
      tooManyRows: "Demasiadas filas",
      busy: "Cargando ...",
      feedbackBtn: "Dar feedback",
      shortcutsBtn: "Mostrar todos los atajos",
      shortcutsHint: "¿Quieres usar Reproducir o Pausar? Use Control + Espacio",
      shortcutHintAltClick:
        "¿Quieres que el audio salte al hacer clic en el texto? Mantenga pulsada la tecla Alt",
      shortcutHintNewLine:
        "¿Quieres añadir una nueva línea dentro de un segmento? Pulse Shift + Enter",
      shortcutsTitle: "Atajos de teclado",
      ariaShortcutsClose: "Cerrar atajos",
      replayTour: "Repetir Tour",
      errorEmptySegments:
        "Amber no reconoció ninguna palabra en el idioma que has seleccionado. Por favor, asegúrate de que tu audio tenga el idioma hablado y que te queden suficientes créditos. No dudes en contactarnos si tienes alguna pregunta!",
      errorLoadAudio: "Error al intentar cargar el archivo",
      errorLoadXML: "Error al intentar cargar el archivo",
      errorReadAudio: "Error al leer el archivo de audio",
      loadingAudio: "Cargando audio...",
      loading: "Cargando transcripción...",
      parsing: "Analizando transcripción...",
      speakerClear: "Borrar interlocutor",
      speakerAddBtn: "Añadir nuevo",
      speakerAddTitle: "Añadir interlocutor",
      speakerEditTitle: "Editar interlocutor",
      findreplTitle: "Buscar y sustituir",
      findreplFindLabel: "Buscar:",
      findreplReplLabel: "Sustituir por:",
      findreplReplBtn: "Sustituir todos",
      infoTitle: "Información de la transcripción",
      infoClose: "Cerrar información de la transcripción",
      infoNameLabel: "Nombre",
      infoDateLabel: "Fecha",
      infoCancel: "Cancelar",
      infoSave: "Guardar",
      infoSaving: "Guardando...",
      feedbackTitle: "Feedback",
      feedbackRatingLabel: "¿Qué tal la calidad de la transcripción?",
      feedbackCommentLabel: "Ayúdanos a mejorar",
      feedbackSubmit: "Enviar",
      feedbackSubmitting: "Enviando...",
      feedbackVal1: "No ha cumplido mis expectativas",
      feedbackVal2: "Necesita mejorar",
      feedbackVal3: "OK",
      feedbackVal4: "Ha cumplido mis expectativas",
      feedbackVal5: "Ha sobrepasado mis expectativas",
      exportBtn: "Exportar",
      exportXML: "XML",
      exportWord: "Word",
      autosaveSuccess: "Todos los cambios guardados",
      autosavePending: "Guardando...",
      autoSaveSaved: "Guardado",
      autoSaveError: "Error en el proceso de guardado",
      autoSaveTryAgain: "Click aquí para intentar de nuevo",
      metaCreated: "Creado",
      metaLength: "Extensión",
      metaStartTimeOffset: "Hora de inicio",
      metaStartTimeOffsetLabel: "Hora de inicio (hh:mm:ss:ms)",
      metaStartTimeOffsetTitle: "Realinear código tiempo de inicio",
      metaStartTimeOffsetDescription:
        "Realinear el código de tiempo de inicio para sincronizarlo con la fuente original. Introduce la hora, que debe añadirse a todas las marcas de tiempo originales de la transcripción.",
      metaStartTimeOffsetSubmit: "Realinear el código de tiempo",
      metaWords: "Palabras",
      metaPlaybackRate: "Velocidad",
      metaPlaybackRateTip: "Cambiar velocidad de reprodución",
      controlsTranslation: "Añadir nuevo idioma",
      addNewLanguage: "Nuevo idioma agregar",
      controlsSpellCheck: "Cambia el corrector ortográfico",
      controlsSpellCheckShortcut: "(ctrl+g)",
      controlsReplace: "Sustituir",
      controlsReplaceShortcut: "(ctrl+f)",
      controlsUndo: "Deshacer",
      controlsUndoShortcut: "(ctrl+z)",
      controlsRedo: "Rehacer",
      controlsRedoShortcut: "(ctrl+shift+z)",
      controlsHighlight: "Destacar",
      controlsHighlightShortcut: "(ctrl+h)",
      controlsSubtitles: "Subtítulos",
      controlsSubtitlesShortcut: "Convertir a subtítulos",
      rewindBtn: "Retroceder",
      resumeBtn: "Continuar",
      ariaPlay: "Reproducir",
      ariaPause: "Pausar",
      blockTextLength: "{textLength} caracteres",
      adjustStartTimestampHeader: "Ajustar la marca de tiempo de inicio",
      adjustEndTimestampHeader: "Ajustar la marca de tiempo del final",
      adjustDialogSubmit: "Ajustar",
      adjustDialogCancel: "Cancelar",
      adjustTimestampDescription:
        "Las marcas de tiempo mínimas y máximas están determinadas por las palabras 'anterior' y 'siguiente'..",
      subtitlesDialogHeader: "Convertir a subtítulos",
      subtitlesDialogDescription:
        "Convierta automáticamente tu transcripción en frases listas para subtítulos. Amberscript dividirá el texto en frases más pequeñas que quepan en la pantalla.",
      subtitlesDialogNote:
        "Nota: Esta acción no puede ser revertida automáticamente. Sin embargo, su texto seguirá siendo editable.",
      subtitlesDialogAdvancedOptions: "Opciones avanzadas",
      subtitlesDialogSubmit: "Convertir",
      subtitlesDialogSubmitLoading: "Por favor, espere",
      subtitlesDialogCancel: "Cancelar",
      noEditingForMobileOrTablet:
        "De momento Amberscript no está disponible para edición en su dispositivo. Amberscript está optimizado en Google Chrome, aunque también es compatible con Microsoft Edge, Apple Safari y Mozilla Firefox.",
      emailValidationTitle: "El correo electrónico aún no está confirmado",
      emailValidationDescription:
        "Para exportar o copiar tu transcripción, por favor confirma tu correo electrónico.",
      btnAlreadyActivated: "Ya he confirmado mi correo electrónico",
      btnSendActivationAgain: "Reenviar email de verificación",
      btnActivationSent: "Email de verificación enviado!",
      alertLoggedOutTitle: "Se necesita iniciar sesión",
      alertLoggedOutDescription:
        "Te has desconectado en otra pestaña. Por favor, vuelva a iniciar sesión para utilizar Amberscript.",
      alertLoggedOutButton: "Ir al inicio de sesión",
      btnApproveOrReject: "Aprobarlo o rechazarlo...",
      btnDetails: "Detalles",
      dialogJobFeedback: {
        btnApprove: "Aprobar",
        btnReject: "Rechazar",
        title: "Aprobar o rechazar el cheque de calidad",
        rateTranscriber:
          "Califique a {email} en cada área para este trabajo, siendo 1 más bajo, 5 siendo la más alta calidad. ¿Necesitas ayuda? Lea las directrices de control de calidad.",
        textAreaPlaceholder: "Añadir notas...",
        notesExplanation:
          "Requerido: agregue notas, incluso cuando se aprueba, por lo que el transcriptor puede ver sus comentarios.",
        transcription: "Tekst",
        syntax: "Richtlijnen",
        grammar: "Spelling/Tikfouten/Grammatica",
        punctuation: "Interpunctie/Syntax",
        research: "Investigación (nombres y jerga)",
        difficulty: "Nivel de dificultad",
        difficultyExplanation:
          "Esta puntuación no es parte del cálculo de la puntuación total.",
        total: "Calificación total de la transcripción",
        tooltip: {
          transcription:
            "It is checked whether the spoken text has been correctly converted into written text. This includes the accurate reproduction of words, sentences and statements without errors or omissions, in accordance with the guidelines. It is checked whether there were any listening errors that could have led to inaccurate or incorrect interpretation of the transcriptions. (30%)",
          syntax:
            "This is where it is checked whether the transcription was carried out in accordance with the established guidelines.This includes the use of time marks for unintelligible passages, the labelling of speakers, the observance of pauses and sentence breaks as well as other guidelines that apply to the transcription. All special guidelines are evaluated especially strictly. It must be checked whether the formatting of the transcript is appropriate. This may include line spacing, font size, arrangement of text elements and other aspects of the visual presentation of the transcript. (25%)",
          grammar:
            "In this category, grammatical errors as well as spelling and typing errors in the transcript are evaluated. The aim is to ensure that the text is both grammatically correct and free of spelling errors according to guidelines. This includes checking for errors such as misspelled words, missing capitalisation and errors in the use of abbreviations. (25%)",
          punctuation:
            "It is checked whether punctuation marks such as full stops and commas have been used correctly to improve the readability and comprehensibility of the text. It also assesses whether the use of quotes is appropriate and whether other punctuation marks have been used correctly. Another aspect of this category is the correct division of long sentences. (15%)",
          research:
            "This category focuses on evaluating the research of technical terms, proper names and other specialised expressions that appear in the transcript. It is checked whether these terms have been spelt and used correctly and, if available, whether terms from a glossary have been used. (5%)",
        },
      },
      SUBTITLE: {
        sliderShorterLabel: "Más corto",
        sliderLongerLabel: "Más largo",
        sliderRecommendedLabel: "Recomendado",
        slidercharsPerSecLabel: "{charsPerSec} caracteres / seg.",
      },
      EXPORT: {
        title: "Exportar texto",
        format: "Formato",
        export: "Exportar",
        optionsTitle: "Opciones de exportación",
        optionsTimestamps: "Incluir marcas de tiempo",
        optionsSpeakers: "Incluir oradores",
        optionsIncludeHighlights: "Incluir marca de tiempo",
        optionsHighlights: "Exportar solamente marcas de tiempo",
        maxCharsPerRow: "Caracteres máximos por fila",
        milliseconds: "Milisegundos",
        fps: "FPS",
        cancel: "Cancelar",
        ok: "Exportar",
        transcribedByAmberScript:
          "Esta conversación a texto fue creada por www.amberscript.com",
        wayOfExport: "¿Cómo desea exportar su archivo?",
        text: "Texto",
        textDesc:
          "Word Doc o TXT. Genial para transcripciones de entrevistas, reuniones y etiquetado de medios.",
        subtitleDesc:
          "SRT, VTT o EBU-STL. Usado para redes sociales, Youtube, reproductores de video y más.",
        video: "Video",
        videoDesc:
          "Descarga el video con o sin subtítulos incluidos. Genial para compartir vídeos con subtítulos para las redes sociales.",
        rules: "Reglas",
        burnSubToVideo: "Añadir los subtítulos al video - Próximamente",
        subtitleRule: "Máximo {maxLine} líneas, {maxChar} caracteres por línea",
        realignRecommendation:
          "Recomendamos realinear las marcas de tiempo antes de la exportación.",
        realignRecommendationDesc:
          "Editar tu transcripción significa que el audio debe ser realineado para que coincida con tus ediciones.",
        realignRecommendation1: "Rápido",
        realignRecommendation1Desc:
          "Reajuste rápido y general. Puede que no sea 100% exacto.",
        realignRecommendation2: "Completo - Próximamente",
        realignRecommendation2Desc:
          "Un reajuste completo y preciso de su audio. Esto puede llevar varios minutos.",
        realignRecommendation3: "Exportar sin realinear",
        realignRecommendation3Desc:
          "Sus marcas de tiempo pueden no ser exactas.",
        emailNotification:
          "Recibirás un correo electrónico cuando tu archivo esté listo para ser descargado.",
        options: "Opciones",
        whatIsJson:
          "JSON es un formato de datos que puede ser usado para aplicaciones de software.",
        audio: "Audio",
        audioDesc: "Descargue su archivo multimedia original.",
        downloadAudio: "Descargue el Audio",
        downloadVideo: "Descargar Video",
        exportSubtitleTitle: "Exportar Subtítulos",
        custom: "Personalizar",
        maxNrRows: "Número máximo de lineas",
        maxCharPerRow: "Máximo de caracteres por línea, incluidos los espacios",
        titleTranscript: "Exporta la transcripción",
        downloadFile: "Descarga archivos de audio",
        asAFile: "Como un archivo",
        asAFileDesc:
          "SRT, VTT o EBU-STL. Se puede utilizar en las redes sociales, Youtube, reproductores de vídeo y mucho más.",
        download: "Descargue",
        asAVideoWithBurnIn: "Como vídeo con subtítulos grabados",
        asAVideoWithBurnInDesc:
          "Los subtítulos aparecerán en el archivo de vídeo de forma permanente. Exportar como mp4, etc.",
        createNewVersion: "Cree una nueva versión",
        lastExport: "Última exportación:",
        additionalOptions: "Opciones adicionales",
        downloadAsText: "Descarga como texto",
        downloadAudioFile: "Descarga archivos de audio",
        downloadVideoFile: "Descarga archivos de video",
        postVideoOnYoutube: "Publicar vídeo en youtube",
        intelligentExport: "Exportación inteligente",
        summary: "Resumen",
        summaryTooltip: "Genere un resumen de su transcripción",
        anonymization: "Anonimización",
        anonymizationTooltip:
          "Anonimice todos los datos personales (nombres, direcciones, etc.) de su transcripción.",
        cleanRead: "Lectura limpia",
        cleanReadTooltip:
          "Genere una transcripción editada para facilitar su lectura.",
      },
      BURN_IN: {
        processingVideo: "Procesamiento de vídeo",
        confirmationTitle: "¿Estás contento con tu archivo?",
        confirmationText:
          "Sólo puede grabar la versión actual de estos subtítulos una vez. Si editas el archivo de subtítulos después y quieres volver a grabarlos, se aplicará un cargo adicional.",
        continue: "Sí, continúa",
        back: "No, sigue editando",
        successTitle: "Pedido realizado con éxito",
        successText1:
          "Tardará un tiempo en procesarse. Una vez completado, recibirás una notificación y podrás descargar el vídeo a través de la pantalla de",
        successText2: "del panel de control o en el editor.",
        export: "Exportación",
        returnToEditor: "Volver al editor",
        continueToDashboard: "Continuar al panel de control",
        notAvailableText: "¿Quiere crear una exportación adicional?",
        notAvailableLink: "Contactar con servicio al cliente",
      },
      toast: {
        onCopy: "Copiar, pegar y cortar no está disponible.",
        onCut: "Copiar, pegar y cortar no está disponible.",
        onPaste: "Copiar, pegar y cortar no está disponible.",
      },
      RPT: {
        title: "Déjanos hacer la edición por ti",
        description:
          "Al solicitar el servicio de transcripción manual nuestros expertos en idiomas tendrán acceso para corregir tu expediente. Los cambios de tu archivo actual se restablecerán y tu archivo se bloqueará hasta que las correcciones terminen.",
        description2: "Haz clic en OK para proceder a la comprobación",
      },
      BANNER: {
        firstStepTitle: "Comprueba y revisa tu transcripción.",
        firstStepDescTranscriber:
          "Puedes saltas este paso, pero puede haber errores en los subtítulos.",
        firstStepDesc:
          "Puedes saltar este paso, pero puede haber errores en los subtítulos. También puedes solicitar una revisión a uno de nuestros expertos en idiomas.",
        secondStepTitle:
          "¿Has terminado de editar? Pulsa Crear subtítulos para alinear y formatear los subtítulos.",
        secondStepDesc:
          "También podrás guardar la transcripción en tus archivos.",
      },
      PRE_QC: {
        emptyState: "Recibe rápidamente verificación sobre su documento.",
        runPreQCBtn: "Activa Asistente de calidad",
        spellingIssueFound: " problema de ortografía encontrado",
        spellingIssuesFound: " problemas ortográficos encontrados",
        grammarIssueFound: " problema gramatical encontrado",
        grammarIssuesFound: " problemas gramaticales encontrados",
        cleanreadIssueFound: " problema de lectura limpia encontrado",
        cleanreadIssuesFound: " problemas de lectura limpia encontrados",
        inaudibleIssueFound: " etiqueta inaudible encontrada",
        inaudibleIssuesFound: " etiquetas inaudibles encontradas",
        noSuggestion: "No hay sugerencias",
        loadingSuggestions: "cargando sugerencias...",
        noIssueNotice: "No se ha encontrado ningún problema. ¡Buen trabajo!",
        formatting: " errores de formato",
        formattingIssueFound: " problema de formato",
        formattingIssuesFound: " problemas de formato",
        reportTitle: "Informar de los falsos positivos",
        reportBtn: "Informar de los falsos positivos",
        reportComment: "Comenta",
        reportCommentPlaceholder: "Introduce tu comentario aquí",
        spellingInfoTooltip:
          "La ortografía de algunas palabras puede ser incorrecta. Considera corregirlas o informa de falsos positivos haciendo clic en el icono de la bandera.",
        inaudibleInfoTooltip:
          "Esta sección ha sido marcada como incomprensible. Asegúrate de utilizar esta etiqueta sólo cuando el discurso no sea claro.",
        grammarInfoTooltip:
          "Se ha encontrado un error gramatical. Comprueba las mayúsculas al principio de la frase.",
        cleanReadInfoTooltip:
          "Se han detectado algunas infracciones de las directrices de lectura limpia. Considera la posibilidad de eliminar palabras repetidas, etiquetas no permitidas y palabras de argot.",
        formattingInfoTooltip:
          "Se han detectado algunas infracciones de las directrices sobre subtítulos. Considera la posibilidad de ajustar los límites CPL/CPS, eliminar los símbolos no permitidos y tener cláusulas más cortas.",
      },
    },
    CREATE_SUBTITLES: {
      title: "Ayuda a nuestro algoritmo a hacer su mejor trabajo.",
      title1:
        "Utilizamos el aprendizaje automático para alinear los subtítulos con el audio en un formato leíble.",
      title2:
        "Esta automatización te permite dedicar menos tiempo a corregir la alineación y el formato de los subtítulos.",
      title3:
        "Corregir los errores de la transcripción antes de crear los subtítulos los hace aún más precisos.",
      note1:
        "Todos los archivos de subtítulos en idiomas distintos del inglés, alemán y neerlandés que duren más de 10 minutos deben enviarse primero para su alineación a Operaciones.",
      note2:
        "Una vez que conviertas este archivo en subtítulos, su transcripción dejará de estar disponible. Por favor, exporte la transcripción ahora si la necesitas más adelante.",
      note3:
        "El archivo desaparecerá temporalmente de la pestaña de trabajos actuales, y volverá a aparecer una vez que esté completamente convertido.",
      note4:
        "Nuestro algoritmo tardará unos minutos en formatear tus subtítulos.",
      note5: "Te avisaremos cuando estén listos.",
      confirmation1: "He terminado de revisar mi transcripción",
      rule1:
        "Máximo {maxLinesPerSegment} linea, {maxCharsPerLine} carácteres por linea, y {maxDisplayTimePerLine} segundos de pantalla",
      rule2: "Máximo numero de líneas",
      rule3: "Máximo carácteres por línea (CPL)",
      rule5: "Tiempo máximo de visualización por línea",
      customRules: "Reglas personalizadas",
      customRulesDescription:
        "CPL, CPS, Duración mín./máx., Líneas por subtítulo, Espacio entre fotogramas, Recuento",
      includeSpacesInCount: "Incluir espacios en el recuento de caracteres",
      includeSpecialCharactersInCount:
        "Incluir caracteres especiales (#,&,%..) en el recuento de caracteres",
      maxCharsPerSecond: "Máximo de caracteres por segundo",
      minSubtitleDuration: "Duración mínima de los subtítulos",
      maxSubtitleDuration: "Duración máxima de los subtítulos",
      minFrameGap: "Espacio mínimo entre fotogramas",
      netflixRules: "Reglas de Netflix",
      msgSuccess1:
        "El proceso se ha iniciado con éxito, será redirigido de nuevo a la plataforma",
      msgSuccess2:
        "El proceso se ha iniciado con éxito, usted será redirigido de nuevo al panel de control",
      error1:
        "Se ha producido un error desconocido, por favor, inténtelo de nuevo",
      button1: "Crear subtítulos",
    },
    OriginalText: {
      title: "Mostrar original",
      transcript: "Transcripción",
    },
    SPELLCHECK: {
      title: "¿Cómo se activa el control ortográfico?",
      clickHere: "Haz clic aquí",
      linkDesc:
        "para obtener instrucciones completas sobre cómo activar la corrección ortográfica en Chrome",
      step1: "Accede a la configuración de Chrome",
      step2: "Selecciona configuración avanzada",
      step3: "Haz clic en Idiomas",
      step4: "Abre este enlace en una nueva pestaña de Chrome",
      step5: "En la sección de idiomas, añade el idioma deseado",
      step6: "Reinicia Chrome para aplicar los cambios",
    },
    LOGIN: {
      title: "Bienvenido de vuelta",
      subtitle: "Acceda a tu cuenta",
      alreadyQuestion: "Nuevo?",
      alreadyAction: "Regístrate ahora.",
      btn: "Acceso",
      emailOrUsername: "Email o nombre de usuario",
      loginFormDescription:
        "Usted está desconectado o no está autorizado para ver esta página. Por favor, inicie sesión de nuevo con la cuenta correcta.",
      errorLogin: "Combinación inválida de email y contraseña",
      forgotPassword: "¿Has olvidado tu contraseña?",
      loggedinActionBtn: "Ir a mis registros",
    },
    PASSWORDRESET: {
      alreadyQuestion: "¿Necesitas ayuda?",
      alreadyAction: "Contáctanos en",
      actionLogin: "Iniciar sesión",
      actionRegister: "Regístrate",
      actionOr: "Entrar",
      loggedinActionBtn: "Ir a mis registros",
    },
    PASSWORDRECOVER: {
      title: "¿Has olvidado tu contraseña?",
      subtitle: "Crea uno nuevo aquí.",
      btn: "Restablecer mi contraseña",
      hintUsername:
        "Este es el nombre de usuario que usaste para crear tu cuenta en Amberscript.",
      or: "o",
      hintEmail:
        "Este es el correo electrónico que usaste para crear tu cuenta en Amberscript.",
    },
    PASSWORDCHANGE: {
      title: "Cambiar contraseña",
      subtitle:
        "Crear una nueva contraseña para volver a iniciar sesión en Amberscript",
      btn: "Confirmar mi contraseña",
    },
    EMAILVALIDATE: {
      loading: "Validación en progreso...",
      inviteTitle: "Gracias por registrarte.",
      inviteSubtitle:
        "Un correo electrónico para activar tu cuenta ha sido enviado a",
      successTitle: "¡Hecho!",
      successSubtitle:
        "Gracias por validar tu correo electrónico. Tu cuenta está ahora activa.",
      errorTitle:
        "Este enlace de validación de correo electrónico no es correcto.",
      errorSubtitle: "Si el problema persiste, contacta con nosotros en",
      loggedinActionBtn: "Ir a mis registros",
    },
    PLAN: {
      hour: "Hora",
      select: "Selecionar",
      unsubscribeBtn: "Anular suscripción",
      unsubscribeMsg:
        "¿Estás seguro de que quieres anular la suscripción de tu plan {{ plan }}?",
      unsubscribeOk: "Continuar",
      unsubscribeCancel: "Cancelar",
      yourCurrent: "Tu plan actual",
      vatDisclaimer: "Precios excl. IVA",
      loading: "Cargando planes...",
      noResults: "No hay planes disponibles de momento",
      showPlans: "Mostrar planes",
    },
    RECORDS: {
      upload: "Subir",
      name: "Nombre",
      words: "Palabras",
      length: "Extensión",
      owner: "Titular",
      lastModified: "Última modificación",
      created: "Creado",
      more: "Más",
      ariaMore: "Abrir menú de acciones",
      share: "Compartir",
      delete: "Eliminar",
      deleting: "Eliminando...",
      deletedSuccess: "{{ recordId }} eliminado con éxito.",
      slicedFileMsg:
        "Obtén más minutos, este archivo de audio ha sido cortado por falta de créditos.",
      invalid: "Archivo inválido",
      errorGeneric: "Esta transcripción parece estar dañada.",
      loading: "Cargando tus ambers...",
      noResults: "Aún no se ha creado ningún amber",
      tipUploading: "Subiendo...",
      tipOpen: "Programado para transcripción",
      tipBusy: "Transcribiendo...",
      tipDone: "Listo",
      tipError: "Dañado",
      scheduled:
        "{{ audioName }} está programada para transcripción. Esto puede tardar unos minutos.",
      transcribing: "Transcribiendo {{ audioName }}, {{ progress }}...",
      transcribed: "Transcripción de {{ audioName }} está lista.",
      uploadErrorServer: "Error del servidor. Error al subir {{ audioName }}.",
      uploadError:
        "Subida de {{ audioName }} contiene un error, vuelve a intentarlo.",
      uploadProgress: "Subida de {{ audioName }}:",
      uploadStart: "Comenzó a subir {{ audioName }}.",
      uploaded: "Subida del archivo {{ audioName }} ha finalizado.",
      uploadhelpTitle: "Subiendo en Amberscript",
      uploadhelpTxt: "Amberscript admite los siguientes formatos de audio:",
      uploadUnsupprtedFormatTitle: "Formato no compatible",
      uploadUnsupprtedFormatMsg:
        "Necesitas seleccionar un archivo de audio. Formatos compatibles:",
      uploadUnsupprtedFormatBtn: "Formato no compatible, conviértalo",
      uploadConfirmTitle: "Listo para la transcripción!",
      uploadConfirmMsgTop:
        "Nota: asegúrate de que la calidad de tu audio sea suficientemente buena. Asumimos que tus archivos:",
      uploadconfirmList1: "Contiene lenguaje claro",
      uploadconfirmList2: "No hay ruido de fondo",
      uploadconfirmList3: "Los interlocutores no tienen acentos fuertes",
      uploadConfirmMsgBottom:
        "Si alguno de estos criterios no se cumple, Amberscript no puede garantizar transcripciones de alta calidad.",
      uploadconfirmSelectASR: "Seleccione el idioma de transcripción",
      uploadConfirmOk: "Proceder",
      uploadConfirmCancel: "Cancelar",
      unsupportedUploadTitle: "Subida no compatible",
      unsupportedUploadMsg:
        "Lo sentimos pero tu navegador no es compatible con la subida de archivos de audio, cambia a Google Chrome",
      unsupportedUploadBtn: "Hecho",
      deleteMsg: "¿Estás seguro de que quieres borrar '{{ name }}'?",
      deleteOk: "Confirmar",
      deleteCancel: "Cancelar",
      noCreditTitle: "Ampliar plan",
      noCreditMsg:
        "Adquiera minutos o un plan pra continuar transcribiendo archivos.",
    },
    REGISTER: {
      title: "Crear cuenta gratis",
      subtitle: "Transcribe en cualquier formato 30 minutos GRATIS",
      alreadyQuestion: "¿Tiene una cuenta?",
      alreadyAction: "Iniciar sesión",
      btn: "Registrar",
      forgotPassword: "¿Olvidaste tu contraseña?",
      createPwd: "Elija una contraseña",
      confirmPwd: "Confirmar contraseña",
      loggedinActionBtn: "Ir a mis registros",
    },
    SHORTCUTS: {
      playPause: "Reproducir y pausar tu archivo de audio",
      rewind: "Retrocede tu audio 5 segundos",
      forward: "Adelanta tu audio 5 segundos",
      skip: "Saltar una palabra",
      reverse: "Revertir una palabra",
      highlight: "Destacar texto",
      undo: "Deshacer",
      redo: "Rehacer",
      altClick: "Saltar audio al hacer clic",
    },
    STATUS: {
      actionBtn: "Obtener plan",
      remainingMsg: "Saldo de transcripción: {{ balance }}",
    },
    UPLOADS: {
      id: "ID",
      owner: "Titular",
      length: "Extensión",
      date: "Fecha",
      noId: "No se ha especificado ningún ID",
      loading: "Cargando archivos...",
      noResults: "No hay subidas todavía",
    },
    TOUR: {
      back: "Volver",
      close: "Cerrar",
      last: "¡Gracias!",
      next: "Siguiente",
      skip: "Saltar",
      dismissBtn: "No vuelvas a mostrar esto.",
      EDITOR: {
        beginTitle: "Bienvenido",
        begin:
          "En este tour te mostramos cómo sacar el máximo provecho de Amberscript",
        altClick:
          "Avance rápidamente el audio pulsando la tecla Alt mientras hace clic en una palabra determinada",
        play: "Con Reproducir se reproduce el archivo de audio. Puedes parar y continuar fácilmente con el atajo ctrl +  espacio",
        rewind:
          "Cuando utilizas este botón, retrocedes automáticamente 5 segundos. El atajo para esto es ctrl + r",
        highlight:
          "Con este botón puedes destacar fácilmente en tu línea de audio. Selecciona un fragmento de texto en la transcripción y observa el destaque en tu texto",
        shortcuts:
          "Este botón muestra otros atajos útiles que puedes usar para trabajar aún más rápido con Amberscript",
        startTime:
          "Puedes ajustar el código de tiempo de inicio de cada párrafo para sincronizarlo con la fuente original, como un vídeo, haciendo clic aquí.",
        end: "¡Buen trabajo! ¿Alguna duda o consejo para mejorar? No dude en ponerse en contacto <a href='mailto:info@amberscript.com'>info@amberscript.com</a>",
      },
    },
    REFERRAL: {
      title: "Ayuda a tus amigos con las transcripciones",
      description:
        "Invita a tus amigos a usar Amberscript - por cada persona que se registre, recibirás {signupCreditMinutes} minutos GRATIS.",
      terms: "Términos aplicables",
      enterEmail: "Ingrese dirección de correo electrónico",
      invitationSent:
        "Tu invitación ha sido enviada. ¡Siéntete libre de invitar a más amigos!",
      sendButton: "Enviar Invitaciones",
      shareInviteLink: "Comparte tu enlace de invitación",
      transcriptionCredit: "Tu crédito de transcripción",
      pending: "Pendiente",
      earned: "Obtenido",
      copy: "Copiar",
      copied: "¡Copiado!",
      errorTitle: "El código de invitación ha caducado",
      errorDescription:
        "Te informaremos de las nuevas campañas a través del correo electrónico o las redes sociales.",
    },
    REALIGN: {
      btnName: "Reajustar marcas de tiempo",
      dialogTitle: "Alineación en curso",
      dialogContent:
        "Mejorando las marcas de tiempo de la transcripción, por favor espere...",
    },
    TRANSACTIONS: {
      id: "ID",
      owner: "Titular",
      seconds: "Segundos",
      date: "Fecha",
      noId: "No se ha especificado ningún ID",
      loading: "Cargando transacciones...",
      noResults: "No hay transacciones todavía",
    },
    THANKS: {
      mainMsg: "¡Gracias!",
      summaryTitle: "Resumen del pedido",
    },
    SCRAMBLED_DIALOG: {
      title: "Oops! Se ha producido un error en su documento",
      description1:
        "El texto de su documento se ha desordenado accidentalmente.",
      description2:
        "Como resultado, su documento tiene que ser restaurado a su estado anterior. Su última edición se perderá, ya que la página deberá ser refrescada.",
      titleFeedback:
        "Por favor, indíquenos qué acción realizó antes de ver este diálogo para que podamos solucionar el problema.",
      descriptionButton: "La página se actualizará automáticamente",
    },
    COUNTRIES: {
      AFG: "Afganistán",
      ALA: "Islas Åland",
      ALB: "Albania",
      DZA: "Argelia",
      ASM: "Samoa Americana",
      AND: "Andorra",
      AGO: "Angola",
      AIA: "Anguila",
      ATA: "Antártida",
      ATG: "Antigua y Barbuda",
      ARG: "Argentina",
      ARM: "Armenia",
      ABW: "Aruba",
      AUS: "Australia",
      AUT: "Austria",
      AZE: "Azerbaiyán",
      BHS: "Bahamas",
      BHR: "Bahrein",
      BGD: "Bangladesh",
      BRB: "Barbados",
      BLR: "Bielorrusia",
      BEL: "Bélgica",
      BLZ: "Belice",
      BEN: "Benin",
      BMU: "Bermudas",
      BTN: "Bután",
      BOL: "Bolivia, Estado Plurinacional de Bolivia",
      BES: "Bonaire, San Eustaquio y Saba",
      BIH: "Bosnia y Herzegovina",
      BWA: "Botswana",
      BVT: "Isla Bouvet",
      BRA: "Brasil",
      IOT: "Territorio Británico del Océano Índico",
      BRN: "Brunei Darussalam",
      BGR: "Bulgaria",
      BFA: "Burkina Faso",
      BDI: "Burundi",
      KHM: "Camboya",
      CMR: "Camerún",
      CAN: "Canadá",
      CPV: "Cabo Verde",
      CYM: "Islas Caimán",
      CAF: "República Centroafricana",
      TCD: "Chad",
      CHL: "Chile",
      CHN: "China",
      CXR: "Isla de Navidad",
      CCK: "Islas Cocos (Keeling)",
      COL: "Colombia",
      COM: "Comoras",
      COG: "Congo",
      COD: "Congo, República Democrática del",
      COK: "Islas Cook",
      CRI: "Costa Rica",
      CIV: "Côte d'Ivoire",
      HRV: "Croacia",
      CUB: "Cuba",
      CUW: "Curaçao",
      CYP: "Chipre",
      CZE: "República Checa",
      DNK: "Dinamarca",
      DJI: "Djibouti",
      DMA: "Dominica",
      DOM: "República Dominicana",
      ECU: "Ecuador",
      EGY: "Egipto",
      SLV: "El Salvador",
      GNQ: "Guinea Ecuatorial",
      ERI: "Eritrea",
      EST: "Estonia",
      ETH: "Etiopía",
      FLK: "Islas Malvinas (Falkland Islands)",
      FRO: "Islas Feroe",
      FJI: "Fiji",
      FIN: "Finlandia",
      FRA: "Francia",
      GUF: "Guayana Francesa",
      PYF: "Polinesia Francesa",
      ATF: "Territorios Australes Franceses",
      GAB: "Gabón",
      GMB: "Gambia",
      GEO: "Georgia",
      DEU: "Alemania",
      GHA: "Ghana",
      GIB: "Gibraltar",
      GRC: "Grecia",
      GRL: "Groenlandia",
      GRD: "Grenada",
      GLP: "Guadalupe",
      GUM: "Guam",
      GTM: "Guatemala",
      GGY: "Guernsey",
      GIN: "Guinea",
      GNB: "Guinea-Bissau",
      GUY: "Guyana",
      HTI: "Haití",
      HMD: "Islas Heard y McDonald",
      VAT: "Santa Sede (Ciudad del Vaticano)",
      HND: "Honduras",
      HKG: "Hong Kong",
      HUN: "Hungría",
      ISL: "Islandia",
      IND: "India",
      IDN: "Indonesia",
      IRN: "Irán, República Islámica de",
      IRQ: "Irak",
      IRL: "Irlanda",
      IMN: "Isla de Man",
      ISR: "Israel",
      ITA: "Italia",
      JAM: "Jamaica",
      JPN: "Japón",
      JEY: "Jersey",
      JOR: "Jordan",
      KAZ: "Kazajstán",
      KEN: "Kenya",
      KIR: "Kiribati",
      PRK: "Corea, República Popular Democrática de",
      KOR: "Corea, República de",
      KWT: "Kuwait",
      KGZ: "Kirguistán",
      LAO: "República Democrática Popular Lao",
      LVA: "Letonia",
      LBN: "Líbano",
      LSO: "Lesotho",
      LBR: "Liberia",
      LBY: "Libia",
      LIE: "Liechtenstein",
      LTU: "Lituania",
      LUX: "Luxemburgo",
      MAC: "Macao",
      MKD: "Macedonia, la ex República Yugoslava de Macedonia",
      MDG: "Madagascar",
      MWI: "Malawi",
      MYS: "Malasia",
      MDV: "Maldivas",
      MLI: "Mali",
      MLT: "Malta",
      MHL: "Islas Marshall",
      MTQ: "Martinica",
      MRT: "Mauritania",
      MUS: "Mauricio",
      MYT: "Mayotte",
      MEX: "México",
      FSM: "Micronesia, Estados Federados de",
      MDA: "Moldavia, República de",
      MCO: "Mónaco",
      MNG: "Mongolia",
      MNE: "Montenegro",
      MSR: "Montserrat",
      MAR: "Marruecos",
      MOZ: "Mozambique",
      MMR: "Myanmar",
      NAM: "Namibia",
      NRU: "Nauru",
      NPL: "Nepal",
      NLD: "Holanda",
      NCL: "Nueva Caledonia",
      NZL: "Nueva Zelanda",
      NIC: "Nicaragua",
      NER: "Níger",
      NGA: "Nigeria",
      NIU: "Niue",
      NFK: "Isla Norfolk",
      MNP: "Islas Marianas del Norte",
      NOR: "Noruega",
      OMN: "Omán",
      PAK: "Pakistán",
      PLW: "Palau",
      PSE: "Territorio Palestino Ocupado",
      PAN: "Panamá",
      PNG: "Papúa Nueva Guinea",
      PRY: "Paraguay",
      PER: "Perú",
      PHL: "Filipinas",
      PCN: "Pitcairn",
      POL: "Polonia",
      PRT: "Portugal",
      PRI: "Puerto Rico",
      QAT: "Qatar",
      REU: "Reunión",
      ROU: "Rumania",
      RUS: "Federación Rusa",
      RWA: "Ruanda",
      BLM: "Saint Barthélemy",
      SHN: "Santa Helena, Ascensión y Tristán da Cunha",
      KNA: "Saint Kitts y Nevis",
      LCA: "Santa Lucía",
      MAF: "San Martín (parte francesa)",
      SPM: "San Pedro y Miquelón",
      VCT: "San Vicente y las Granadinas",
      WSM: "Samoa",
      SMR: "San Marino",
      STP: "Santo Tomé y Príncipe",
      SAU: "Arabia Saudita",
      SEN: "Senegal",
      SRB: "Serbia",
      SYC: "Seychelles",
      SLE: "Sierra Leona",
      SGP: "Singapur",
      SXM: "Sint Maarten (parte holandesa)",
      SVK: "Eslovaquia",
      SVN: "Eslovenia",
      SLB: "Islas Salomón",
      SOM: "Somalia",
      ZAF: "Sudáfrica",
      SGS: "Georgia del Sur y las Islas Sandwich del Sur",
      SSD: "Sur de Sudán",
      ESP: "España",
      LKA: "Sri Lanka",
      SDN: "Sudán",
      SUR: "Surinam",
      SJM: "Svalbard y Jan Mayen",
      SWZ: "Swazilandia",
      SWE: "Suecia",
      CHE: "Suiza",
      SYR: "República Árabe Siria",
      TWN: "Taiwán, Provincia de China",
      TJK: "Tayikistán",
      TZA: "Tanzania, República Unida de",
      THA: "Tailandia",
      TLS: "Timor-Leste",
      TGO: "Togo",
      TKL: "Tokelau",
      TON: "Tonga",
      TTO: "Trinidad y Tobago",
      TUN: "Túnez",
      TUR: "Pavo",
      TKM: "Turkmenistán",
      TCA: "Islas Turcas y Caicos",
      TUV: "Tuvalu",
      UGA: "Uganda",
      UKR: "Ucrania",
      ARE: "Emiratos Árabes Unidos",
      GBR: "Reino Unido",
      USA: "Estados Unidos",
      UMI: "Islas periféricas menores de Estados Unidos",
      URY: "Uruguay",
      UZB: "Uzbekistán",
      VUT: "Vanuatu",
      VEN: "Venezuela, República Bolivariana de",
      VNM: "Viet Nam",
      VGB: "Islas Vírgenes Británicas",
      VIR: "Islas Vírgenes, EE.UU.",
      WLF: "Wallis y Futuna",
      ESH: "Sáhara Occidental",
      YEM: "Yemen",
      ZMB: "Zambia",
      ZWE: "Zimbabwe",
    },
    GLOSSARY: {
      title: "Diccionario",
      names: "Nombre",
      terms: "Términos",
      notFound:
        "No se ha encontrado el diccionario. Por favor, cree un diccionario la próxima vez antes de subir su archivo para mejorar la precisión de su texto.",
    },
    MACHINE_TRANSLATIONS: {
      direct: "Automática",
      perfect: "Profesional",
    },
    EDITOR_LANGUAGE: {
      "en-us": "Inglés (Estados Unidos)",
      "en-uk": "Inglés (Reino Unido)",
      nl: "Holandés",
      de: "Alemán",
      fr: "Francés",
      es: "Espanõl",
      it: "Italiano",
      pt: "Portugués",
      da: "Danés",
      fi: "Finlandés",
      no: "Noruego (Bokmål)",
      sv: "Sueco",
      pl: "Polaco",
      ro: "Rumano",
      uk: "Ucranio",
      ru: "Ruso",
      bg: "Búlgaro",
      cs: "Checo",
      el: "Griego",
      et: "Estonio",
      hu: "Húngaro",
      ja: "Japonés",
      ko: "Coreano",
      lt: "Lituano",
      lv: "Letón",
      sk: "Eslovaco",
      sl: "Esloveno",
      tr: "Turco",
      zh: "Chino",
    },
  },
};
