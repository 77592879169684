import React from "react";
import PropTypes from "prop-types";
import { Box } from "@material-ui/core";

import TimestampBox from "./TimestampBox";
import { MAX_CHARS_ISSUE_TEXT } from "./constants";

export default function TimestampIssueItem(props) {
  const { item } = props;
  const { original_text, start_time } = item;

  const croppedText = (text) => {
    if (text.length > 30) {
      return `"${text.substring(0, MAX_CHARS_ISSUE_TEXT - 1).trim()}..."`;
    }
    return `"${text}"`;
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
      gridColumnGap={3}
    >
      <TimestampBox startTime={start_time} />
      <Box display="flex" flexDirection="row" gridColumnGap={3}>
        {croppedText(original_text)}
      </Box>
    </Box>
  );
}

TimestampIssueItem.propTypes = {
  item: PropTypes.shape({
    original_text: PropTypes.string.isRequired,
    start_time: PropTypes.number.isRequired,
  }).isRequired,
};
