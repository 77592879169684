export const de = {
  locale: "de",
  messages: {
    MISC: {
      formErrRequired: "Diese Angabe ist erforderlich",
      formErrEmail: "Keine gültige E-Mail-Adresse",
      formHintYes: "Ja",
      formHintQuestion: "Klicken Sie auf {{was}}?",
      signedInAs: "Sie sind angemeldet als",
      logout: "Ausloggen",
      youOwner: "Sie",
      closeDialogBtn: "Schließen",
      ariaCloseDialog: "Fenster schließen",
      clearSearch: "Clear search",
      serverErrorDefaultToastMsg: "Serverfehler",
      ienoticeTitle: "Unterstützte Browser",
      ienoticeTxt:
        "Internet Explorer wird nicht unterstützt. Bitte benutzen Sie einen anderen Browser wie Google Chrome oder Microsoft Edge",
      ienoticeDismiss: "Verstanden",
      requestFullTranscriptBodyDialog:
        "Wenn Sie Ihr Transkript von unseren Sprachexpert:innen verbessern lassen wollen, laden Sie es bitte erneut hoch und wählen Sie als Transkriptionsart 'Manuell' aus.",
      requestFullTranscriptTitle: "Erhalten Sie 100% akkuraten Text.",
      requestFullTranscriptBody:
        "Angebot für ein erweitertes Transkriptionsformat von einem:einer unserer erfahrenen Sprachexpert:innen anfragen",
    },
    CONFIRM_SUBMIT: {
      title: "Sind Sie sicher, dass Sie die Datei einreichen möchten?",
      checkbox1:
        "Ich habe die zusätzlichen Notizen überprüft und die kundenspezifischen Anforderungen gegebenenfalls umgesetzt.",
      checkbox2:
        "Der gesamte ASR-Output der Datei wurde mit der Rechtschreib- und Grammatikprüfung kontrolliert.",
      checkbox3: "Das Transkript wurde gemäß den Richtlinien erstellt.",
      back: "Bringen Sie mich zurück zur Datei",
      submit: "Datei zur Überprüfung einreichen",
    },
    TRANSCRIPT_ERROR: {
      message:
        "Du hast keinen Zugriff mehr auf diese Datei. Sie wurde wahrscheinlich neu zugewiesen. Wende dich bei Fragen an Operations.",
      button: "Zurück zum Dashboard",
    },
    NAV: {
      titleAccount: "Account",
      titleAccountNew: "Neuen Benutzer hinzufügen",
      titleAccountMy: "Mein Account",
      titleBilling: "Abrechnung",
      titleInvoices: "Rechnungen",
      titleCheckout: "Ausloggen",
      titleCoupon: "Gutschein einlösen",
      titleInvite: "Freunde einladen",
      titleLogin: "Login",
      titlePasswordReset: "Passwort zurücksetzen",
      titleEmailvalidate: "E-Mail bestätigen",
      titlePlan: "Tarif",
      titleCosts: "Kosten",
      titleRecords: "Ihre Ambers",
      titleRecordsTooltip: "Zurück zum Dashboard",
      titleRegister: "Registrieren",
      titleTransactions: "Transaktionen",
      titleUploads: "Uploads",
      titleDashboard: "Übersicht",
      titleManageUsers: "Benutzer verwalten",
      titleAddUser: "Add user",
      titleManageTranscriptions: "Transkriptionen verwalten",
      tooltipAdmin: "Admin",
      titleAdminUser: "Benutzer verwalten",
      tooltipLanguage: "Sprache ändern",
      newBadge: "neu",
      help: "Hilfe",
      currentPlanFallback: "Vollen Tarif bestellen",
      currentPlanTooltip: "Aktueller Tarif",
    },
    LANGUAGES: {
      en: "Englisch",
      nl: "Holländisch",
      de: "Deutsch",
      fr: "Französisch",
      es: "Spanisch",
      it: "Italienisch",
      pt: "Portugiesisch",
    },
    ACCOUNT: {
      titleForm: "Account Details",
      username: "Benutzername",
      email: "E-Mail",
      firstName: "Vorname",
      lastName: "Nachname",
      company: "Firma",
      pwd: "Passwort",
      newPwd: "Neues Passwort",
      confirmPwd: "Passwort bestätigen",
      changePwd: "Passwort ändern",
      createPwd: "Passwort erstellen",
      msgUsernameMaxlength:
        "Der Benutzer muss weniger als {{Anzahl}} Buchstaben haben.",
      msgPwdLength: "Das Passwort muss mindestens {{Anzahl}} Buchstaben haben.",
      msgPwdPattern:
        "Das Passwort muss mindestens eine Zahl, einen Großbuchstaben, einen Kleinbuchstaben und ein Symbol enthalten",
      msgPwdConfirm: "Das neue Passwort muss bestätigt werden",
      msgPwdMismatch: "Muss mit dem Vorigen übereinstimmen",
      loading: "Details werden geladen...",
      update: "Dateien hochladen",
      updating: "Dateien speichern...",
      save: "Benutzer speichern",
      saving: "Benutzer wird gespeichert",
    },
    ADMIN: {
      add: {
        donateSeconds: "Donate seconds",
      },
      dashboard: {
        loading: "Übersicht wird geladen...",
        noResults: "Keine Übersichtslemente vorhanden",
      },
      transactions: {
        id: "ID",
        owner: "Besitzer",
        seconds: "Sekunden",
        date: "Datum",
        noId: "Keine ID festgelegt",
        loading: "Transaktionen werden geladen...",
        noResults: "Keine Transaktionen vorhanden",
        results: "Transaktionen abrufen:",
      },
      transcriptions: {
        created: "Erstellt\r",
        id: "ID",
        name: "Name",
        user: "Benutzer",
        status: "Status",
        length: "Länge",
        type: "Typ",
        loading: "Transkription wird geladen...",
        noResults: "keine Transkriptionen vorhanden",
        results: "Transkriptionen abrufen:",
      },
      users: {
        created: "Erstellt",
        username: "Benutzername",
        fullname: "Voller Name",
        status: "Status",
        minutes: "Audiozeit",
        pause: "Benutzer pausieren",
        activate: "Benutzer aktivieren",
        delete: "Benutzer löschen",
        subscribed: "Abonniert",
        loading: "Benutzer werden geladen...",
        noResults: "Keine Benutzer vorhanden",
        results: "Benutzer abrufen:",
        deleteConfirm:
          "Wollen Sie den Benutzer ' {{ name }} ' wirklich deaktivieren?",
        deleteOk: "Ja",
        deleteCancel: "Abbrechen",
        search: "Search users",
      },
      user: {
        navBackToUsers: "Alle Benutzer",
        navEdit: "Profil",
        navTranscriptions: "Transkriptionen",
        navTransactions: "Transaktionen",
        navDonate: "Spenden",
        fullName: "Vollständiger Name",
        availableSeconds: "Verfügbare Zeit",
        created: "Erstellt am",
        isSubscribed: "Aktuelles Abonnement",
        isntSubscribed: "Keine Abonnements",
        userType: "Typ",
        status: "Status",
        language: "Preferred language",
        makeAdmin: "Administrative Rechte",
        cancelSubscription: "Cancel subscription",
        cancellingSubscription: "Cancelling...",
      },
      donate: {
        title: "Stunden spenden",
        username: "Name des Empfängers",
        seconds: "Anzahl der Sekunden",
        secondsFormatted: "Stunden : Minuten : Sekunden",
        comment: "Nachricht an Empfänger",
        submit: "Senden",
        submitting: "Sendet...",
      },
      sendvalidationemail: {
        submit: "Send validation email",
        submitting: "Sending",
      },
    },
    BILLING: {
      titleForm: "Rechnungsdetails",
      firstName: "Vorname",
      lastName: "Nachname",
      email: "E-Mail",
      phone: "Telefon",
      company: "Firma",
      vatNumber: "UID Nummer",
      address: "Adresse",
      address2: "Adresse 2",
      city: "Stadt",
      country: "Land",
      zipCode: "Postleitzahl",
      state: "Staat",
      loading: "Details werden geladen...",
      save: "Details speichern",
      saving: "Details werden gespreichert...",
    },
    CHECKOUT: {
      choose: "1. Tarif wählen",
      setup: "2. Bezahlung einrichten",
      confirm: "3. Bestätigung",
      loadingStripe: "Stripe wird geladen...",
      titleCard: "Kartenangaben",
      titleBilling: "Rechnungsdetails",
      titleSummary: "Bestellungszusammenfassung",
      subscribe: "Für Tarif anmelden",
      subscribing: "Anmeldung...",
      info: "Amberscript akzeptiert die folgenden Kredit- und Debitkarten. Zahlungen werden vertraulich von Stripe behandelt.",
      labelNameOnCard: "Name des Kartenbesitzers",
      labelCard: "Kredit- oder Debitkarte",
      termsDisclaimer: "Wenn Sie auf Fortsetzen klicken, stimmen Sie den",
      termsDisclaimerLinkTxt:
        "Allgemeine Geschäftsbedingungen von Amberscript zu",
    },
    SUMMARY: {
      plan: "Tarif:",
      cost: "Kosten:",
      beyond: "Danach",
      hrs: "Std.",
      hour: "Stunde",
      billingCycle: "Abrechnungszyklus:",
      billingInterval: "monatlich",
      total: "Gesamt",
      loading: "Zusammenfassung wird geladen...",
      error: "Zusammenfassung konnten nicht geladen werden :(",
    },
    COSTS: {
      titlePlan: "Aktueller Tarif",
      titleCosts: "Zusammenfassung",
      titleInvoices: "Rechnungsliste",
      noResultsPlan: "Vollen Tarif bestellen",
      noResultsCosts: "Sie haben keine Kosten für diesen Monat",
      noResultsInvoices: "Es gibt noch keine Rechnungen.",
      loadingSummary: "Zusammenfassung wird geladen...",
      loadingInvoices: "Rechnungen werden geladen...",
      summary:
        "In diesem Monat haben Sie {{time}} für den Gesamtpreis von {{cost}} Euro genutzt.",
      remainder:
        "Mit Ihrem Tarif {{planName}} bezahlen Sie {{price}} pro Stunde.",
      remainderB: "Dieser Tarif besteht aus {{num}} kostenlose Stunden",
      remainderC1: "und einer festen Rate von {{cost}} pro {{interval}}.",
      remainderC2: "und keinen zusätzlichen Kosten.\r",
      INVOICES: {
        invoiceNr: "Nr.",
        created: "erstellt",
        description: "Beschreibung",
        monthPeriod: "Monat",
        yearPeriod: "Jahr",
        nettoAmount: "Netto",
        vatAmount: "MwSt.",
      },
    },
    COUPON: {
      title: "Gutschein einlösen",
      inputLabel: "Gutschein code",
      submit: "Einlösen",
      submitting: "Wird gesendet...",
      cancel: "Abbrechen",
      invalidCouponCode: "Coupon Code ungültig",
    },
    EDITOR: {
      tooManyRows: "Zu viele Zeilen",
      busy: "Wird geladen ...",
      feedbackBtn: "Ihr Feedback",
      shortcutsBtn: "Alle Tastenkombinationen",
      shortcutsHint:
        "Zum Abspielen oder Anhalten drücken Sie die Ctrl+Leertaste.",
      shortcutHintAltClick:
        "Sie möchten, dass das Audio zum Cursor springt? Halte alt gedrückt + klicke auf den Text",
      shortcutHintNewLine:
        "Sie möchten eine neue Zeile innerhalb eines Segments hinzufügen? Drücken Sie Shift + Enter",
      shortcutsTitle: "Tastenkombinationen",
      ariaShortcutsClose: "Tastenkombinationen schließen",
      replayTour: "Tour noch einmal abspielen",
      errorEmptySegments:
        "Amber hat keine Wörter in der ausgewählten Sprache erkannt. Bitte stellen Sie sicher, dass Ihre Sprachdatei gesprochene Sprache und Sie genügend Credits übrig haben. Bei Fragen können Sie uns gerne kontaktieren!",
      errorLoadAudio: "Fehler beim Laden der Datei",
      errorLoadXML: "Fehler beim Laden der Datei",
      errorReadAudio: "Error reading the audio file",
      loadingAudio: "Audio wird geladen...",
      loading: "Transkription wird geladen...",
      parsing: "Transkription wird erstellt…",
      speakerClear: "Sprecher entfernen",
      speakerAddBtn: "Hinzufügen",
      speakerAddPlaceholder: "Sprecher hinzufügen",
      speakerAddTitle: "Sprecher hinzufügen",
      speakerEditTitle: "Edit speaker",
      findreplTitle: "Suchen und ersetzen",
      findreplFindLabel: "Suchen:",
      findreplReplLabel: "Ersetzen durch:",
      findreplReplBtn: "Alle Ersetzen",
      infoTitle: "Transkriptionsinfo",
      infoClose: "Transkriptionsinfo schließen",
      infoNameLabel: "Name",
      infoDateLabel: "Datum",
      infoCancel: "Schließen",
      infoSave: "Speichern",
      infoSaving: "Wird gespeichert...",
      feedbackTitle: "Feedback",
      feedbackRatingLabel: "Wie gut war die Qualität der Transkription?",
      feedbackCommentLabel: "Hinterlassen Sie einen Kommentar",
      feedbackSubmit: "Senden",
      feedbackSubmitting: "Sendet...",
      feedbackVal1: "Schlecht",
      feedbackVal2: "So lala",
      feedbackVal3: "OK",
      feedbackVal4: "Gut",
      feedbackVal5: "Sehr gut",
      exportBtn: "Exportieren",
      exportXML: "XML",
      exportWord: "Word",
      autosaveSuccess: "Alle Änderungen gespeichert",
      autosavePending: "Speichern...",
      autoSaveSaved: "Gespeichert",
      autoSaveError: "Fehler beim Speichern",
      autoSaveTryAgain: "Klicken Sie hier um es erneut zu versuchen",
      metaCreated: "Erstellt",
      metaLength: "Länge",
      metaStartTimeOffset: "Startzeit",
      metaStartTimeOffsetLabel: "Startzeit (hh:mm:ss:ms)",
      metaStartTimeOffsetTitle: "Timecode neu anordnen",
      metaStartTimeOffsetDescription:
        "Timecode neu anordnen, um mit der original Quelle zu synchronisieren. Gib die Zeit ein, welche zu allen original timestamps des Transkriptes hinzuaddiert werden soll",
      metaStartTimeOffsetSubmit: "Übernehmen",
      metaWords: "Wörter",
      metaPlaybackRate: "Geschwindigkeit",
      metaPlaybackRateTip: "Abspielgeschwindigkeit ändern",
      controlsTranslation: "Übersetzungen hinzufügen",
      addNewLanguage: "Neue Sprache hinzufügen",
      controlsSpellCheck: "Schalte die Rechtschreibprüfung ein",
      controlsSpellCheckShortcut: "(ctrl+g)",
      controlsReplace: "Ersetzen",
      controlsReplaceShortcut: "(ctrl+f)",
      controlsUndo: "Rückgängig",
      controlsUndoShortcut: "(ctrl+z)",
      controlsRedo: "Wiederherstellen",
      controlsRedoShortcut: "(ctrl+shift+z)",
      controlsHighlight: "Markieren",
      controlsHighlightShortcut: "(ctrl+h)",
      controlsSubtitles: "Untertitel",
      controlsSubtitlesShortcut: "In Untertitel konvertieren",
      rewindBtn: "Zurückspulen",
      resumeBtn: "Weiter",
      ariaPlay: "Abspielen",
      ariaPause: "Pause",
      blockTextLength: "{textLength} Buchstaben",
      adjustStartTimestampHeader: "Start Zeitstempel anpassen",
      adjustEndTimestampHeader: "End Zeitstempel anpassen",
      adjustDialogSubmit: "Anpassen",
      adjustDialogCancel: "Abbrechen",
      adjustTimestampDescription:
        "Minimale und maximale Zeitstempel werden durch die Zeitstempel der vorherigen und nächsten Wörter bestimmt.",
      subtitlesDialogHeader: "In Untertitel konvertieren",
      subtitlesDialogDescription:
        "Transkript automatisch in für Untertitel passende Sätze konvertieren. Amberscript teilt den Text in kleinere Absätze auf, die auf den Bildschirm passen.",
      subtitlesDialogNote:
        "Hinweis: Diese Aktion kann nicht automatisch rückgängig gemacht werden. Ihr Text kann jedoch weiterhin bearbeitet werden.",
      subtitlesDialogAdvancedOptions: "Erweiterte Optionen",
      subtitlesDialogSubmit: "Konvertieren",
      subtitlesDialogSubmitLoading: "Bitte warten",
      subtitlesDialogCancel: "Abbrechen",
      noEditingForMobileOrTablet:
        "Derzeit unterstützt Amberscript keine Bearbeitung auf Ihrem Gerät. Amberscript funktioniert am besten auf einem Laptop oder PC mit Google Chrome und unterstützt auch Microsoft Edge, Apple Safari und Mozilla Firefox.",
      emailValidationTitle: "E-Mail ist noch nicht validiert",
      emailValidationDescription:
        "Um Ihr Transkript zu exportieren oder zu kopieren, überprüfen Sie bitte Ihre E-Mail.",
      btnAlreadyActivated: "Ich habe meinen Account bereits validiert",
      btnSendActivationAgain: "Bestätigungs-E-Mail erneut versenden",
      btnActivationSent: "E-Mail-Bestätigung gesendet!",
      alertLoggedOutTitle: "Anmeldung erforderlich",
      alertLoggedOutDescription:
        "Sie haben sich in einem anderen Fenster abgemeldet. Bitte melden Sie sich erneut an um Amberscript zu nutzen.",
      alertLoggedOutButton: "Zum Anmeldebildschirm",
      btnApproveOrReject: "Genehmigen oder ablehnen...",
      btnDetails: "Einzelheiten",
      dialogJobFeedback: {
        btnApprove: "Genehmigen",
        btnReject: "Ablehnen",
        title: "Qualitätsprüfung genehmigen oder ablehnen",
        rateTranscriber:
          "Bewerten Sie {email} in jedem Bereich für diesen Job, 1 niedrigste, 5 ist höchste Qualität. Brauchen Sie Hilfe? Lesen Sie die Qualitätskontrollrichtlinien.",
        textAreaPlaceholder: "Notizen hinzufügen...",
        notesExplanation:
          "Erforderlich: Hinweise hinzufügen, auch bei der Genehmigung, sodass der Transkriber Ihr Feedback sehen kann.",
        transcription: "Tekst",
        syntax: "Richtlijnen",
        grammar: "Spelling/Tikfouten/Grammatica",
        punctuation: "Interpunctie/Syntax",
        research: "Forschung (Namen & Jargon)",
        difficulty: "Schwierigkeitsgrad",
        difficultyExplanation:
          "Diese Punktzahl ist nicht Teil der Gesamtwertberechnung.",
        total: "Total Transkription",
        tooltip: {
          transcription:
            "It is checked whether the spoken text has been correctly converted into written text. This includes the accurate reproduction of words, sentences and statements without errors or omissions, in accordance with the guidelines. It is checked whether there were any listening errors that could have led to inaccurate or incorrect interpretation of the transcriptions. (30%)",
          syntax:
            "This is where it is checked whether the transcription was carried out in accordance with the established guidelines.This includes the use of time marks for unintelligible passages, the labelling of speakers, the observance of pauses and sentence breaks as well as other guidelines that apply to the transcription. All special guidelines are evaluated especially strictly. It must be checked whether the formatting of the transcript is appropriate. This may include line spacing, font size, arrangement of text elements and other aspects of the visual presentation of the transcript. (25%)",
          grammar:
            "In this category, grammatical errors as well as spelling and typing errors in the transcript are evaluated. The aim is to ensure that the text is both grammatically correct and free of spelling errors according to guidelines. This includes checking for errors such as misspelled words, missing capitalisation and errors in the use of abbreviations. (25%)",
          punctuation:
            "It is checked whether punctuation marks such as full stops and commas have been used correctly to improve the readability and comprehensibility of the text. It also assesses whether the use of quotes is appropriate and whether other punctuation marks have been used correctly. Another aspect of this category is the correct division of long sentences. (15%)",
          research:
            "This category focuses on evaluating the research of technical terms, proper names and other specialised expressions that appear in the transcript. It is checked whether these terms have been spelt and used correctly and, if available, whether terms from a glossary have been used. (5%)",
        },
      },
      SUBTITLE: {
        sliderShorterLabel: "Kürzer",
        sliderLongerLabel: "Länger",
        sliderRecommendedLabel: "Empfohlen",
        slidercharsPerSecLabel: "{charsPerSec} Buchstaben / Sek.",
      },
      EXPORT: {
        title: "Text exportieren",
        format: "Format",
        export: "Exportieren",
        optionsTitle: "Exportoptionen",
        optionsTimestamps: "Zeitstempel einbeziehen",
        optionsSpeakers: "Sprecher einbeziehen",
        optionsIncludeHighlights: "Markierungen einbeziehen",
        optionsHighlights: "Nur Markierungen exportieren",
        maxCharsPerRow: "Max. Zeichen pro Zeile",
        milliseconds: "Millisekunden",
        fps: "FPS",
        cancel: "Abbrechen",
        ok: "Exportieren",
        transcribedByAmberScript:
          "Dieses Transkript wurde erstellt mit www.amberscript.com",
        wayOfExport: "Wie möchten sie ihre Datei exportieren?",
        text: "Text",
        textDesc:
          "Word Dokument oder TXT. Perfekt für Interview Transkriptionen, Meetings und Video tagging.",
        subtitleDesc:
          "SRT, VTT oder EBU-STL. Kann für Social Media, Youtube, Videoplayer und mehr genutzt werden.",
        video: "Video",
        videoDesc:
          "Video mit oder ohne eingebrannten Untertiteln herunterladen. Videos mit eingebrannten Untertiteln eignen sich perfekt für Social Media.",
        rules: "Regeln",
        burnSubToVideo: "Untertitel in Video einbrennen - Kommt bald",
        subtitleRule: "Max. {maxLine} Zeilen, {maxChar} Buchstaben pro Zeile",
        realignRecommendation:
          "Wir empfehlen die Zeitstempel automatisch auszurichten vor dem Exportieren.",
        realignRecommendationDesc:
          "Wenn das Transkript bearbeitet wurde müssen die Zeitstempel der Worte zu dem Audio ausgerichtet werden.",
        realignRecommendation1: "Schnell",
        realignRecommendation1Desc:
          "Schnelle und allgemeine Ausrichtung. Kann nicht 100% genau sein.",
        realignRecommendation2: "Voll - Kommt bald",
        realignRecommendation2Desc:
          "Eine gründliche, akkurate Ausrichtung nach dem Audio. Das kann einige Minuten dauern.",
        realignRecommendation3: "Exportieren ohne Ausrichtung",
        realignRecommendation3Desc:
          "Die Zeitstempel können eventuell nicht akkurat sein.",
        emailNotification:
          "Sie empfangen eine E-Mail sobald ihre Datei fertig ist.",
        options: "Optionen",
        whatIsJson:
          "JSON ist ein Datenformat welches für Software Applikationen genutzt werden kann.",
        audio: "Audio",
        audioDesc: "Originaldatei herunterladen",
        downloadAudio: "Audio herunterladen",
        downloadVideo: "Video herunterladen",
        exportSubtitleTitle: "Untertitel exportieren",
        custom: "Benutzerdefiniert",
        maxNrRows: "Maximale Anzahl an Zeilen",
        maxCharPerRow:
          "Maximale Anzahl an Schriftzeichen pro Zeile, einschließlich Leerzeichen",
        titleTranscript: "Transkript exportieren",
        downloadFile: "Audiodatei herunterladen",
        asAFile: "Als Datei",
        asAFileDesc:
          "SRT, VTT oder EBU_STL. Kann für soziale Medien, Youtube, Video Player und mehr genutzt werden.",
        download: "Herunterladen",
        asAVideoWithBurnIn: "Als Video mit eingebrannten Untertiteln",
        asAVideoWithBurnInDesc:
          "Untertitel erscheinen permanent in der Videodatei. Exportieren als mp4, etc.",
        createNewVersion: "Neue Version erstellen",
        lastExport: "Letzter Export:",
        additionalOptions: "Zusätzliche Optionen",
        downloadAsText: "Als Text herunterladen",
        downloadAudioFile: "Audiodatei herunterladen",
        downloadVideoFile: "Videodatei herunterladen",
        postVideoOnYoutube: "Video auf youtube veröffentlichen",
        intelligentExport: "Komfortabler Export",
        summary: "Zusammenfassung",
        summaryTooltip: "Zusammenfassung deines Transkriptes erstellen",
        anonymization: "Anonymisierung",
        anonymizationTooltip:
          "Anonymisiere alle personenbezogenen Daten (Namen, Adressen, etc.) des Transkripts.",
        cleanRead: "Sauberes Lesen",
        cleanReadTooltip:
          "Erstelle ein Transkript, das für eine optimale Lesbarkeit aufbereitet ist.",
      },
      BURN_IN: {
        processingVideo: "Video in Bearbeitung",
        confirmationTitle: "Sind Sie mit Ihrer Datei zufrieden?",
        confirmationText:
          "Die aktuelle Version der Untertitel kann nur ein Mal eingebrannt werden. Falls die Untertitel danach nochmals bearbeitet werden, und Sie diese auch einbrennen wollen, fällt eine zusätzliche Gebühr an.",
        continue: "Ja, weiter",
        back: "Nein, weiter bearbeiteng",
        successTitle: "Bestellung erfolgreich",
        successText1:
          "Die Bearbeitung dauert eine Weile. Sobald fertig, werden Sie benachrichtigt und können Ihr Video über den",
        successText2:
          " Bereich im Dashboard, oder aus dem Editor herunterladen.",
        export: "Export",
        returnToEditor: "Zurück zum Editor",
        continueToDashboard: "Weiter zum Dashboard",
        notAvailableText: "Zusätzlichen Export erstellen?",
        notAvailableLink: "Kundendienst kontaktieren",
        notConverted:
          "Das Dokument muss zuerst in Untertitel umgewandelt werden, bevor diese eingebrannt werden können.",
      },
      toast: {
        onCopy: "Copying, pasting and cutting is not available.",
        onCut: "Copying, pasting and cutting is not available.",
        onPaste: "Copying, pasting and cutting is not available.",
      },
      RPT: {
        title: "Lass uns die Bearbeitung für dich übernehmen",
        description:
          "Wenn du die manuelle Transkription anforderst, erhalten unsere Sprachexperten Zugriff auf deine Dateien, um diese zu korrigieren. Deine bisherigen Änderungen werden zurückgesetzt und die Datei wird gesperrt, bis die Bearbeitung abgeschlossen ist.",
        description2: "Klicke auf OK, um mit dem Checkout fortzufahren",
      },
      BANNER: {
        firstStepTitle: "Überprüfen und überarbeiten Sie Ihr Zeugnis.",
        firstStepDescTranscriber:
          "Sie können dies überspringen, aber es kann sein, dass Ihre Untertitel Fehler enthalten.",
        firstStepDesc:
          "Sie können dies überspringen, aber es kann sein, dass Ihre Untertitel Fehler enthalten. Sie können auch eine Überprüfung durch einen unserer Sprachexperten anfordern.",
        secondStepTitle:
          "Fertig mit der Bearbeitung? Klicken Sie auf Untertitel erstellen, um die Untertitel auszurichten und zu formatieren.",
        secondStepDesc:
          "Sie können die Abschrift auch für Ihre Unterlagen speichern.",
      },
      PRE_QC: {
        submitBtn: "Zur Qualitätskontrolle abgeben...",
        dialogTitle: "Qualitätsassistent",
        submitAnyway: "Mit Abgabe fortfahren",
        viewFix: "Ansehen & korrigieren",
        spelling: " rechtschreibfehler",
        grammar: " grammatikfehler",
        cleanread: " clean Read-Fehler",
        inaudible: " unverständlich-Tags",
        cancel: "Abbrechen",
        submit: "Abgeben",
        confirmationTitle: "Zur Kontrolle abgeben",
        confirmation:
          "Du bist dabei, die Datei zur Kontrolle abzugeben. Dieser Schritt kann nicht rückgängig gemacht werden. Bist du dir sicher, dass du die Datei abgeben willst?",
        emptyState: "Schnell Feedback zur Datei erhalten",
        runPreQCBtn: "Qualitäts-Assistent aktivieren",
        spellingIssueFound: " Rechtschreibfehler gefunden",
        spellingIssuesFound: " Rechtschreibfehler gefunden",
        grammarIssueFound: " Grammatikfehler gefunden",
        grammarIssuesFound: " Grammatikfehler gefunden",
        cleanreadIssueFound: " clean read Fehler gefunden",
        cleanreadIssuesFound: " clean read Fehler gefunden",
        inaudibleIssueFound: " 'unverständlich' Tag gefunden",
        inaudibleIssuesFound: " 'unverständlich' Tags gefunden",
        noSuggestion: "Kein Vorschlag",
        loadingSuggestions: "Vorschläge laden...",
        noIssueNotice: "Es wurden keine Probleme gefunden. Gute Arbeit!",
        formatting: " Formatfehler",
        reportTitle: "Falschen Positiv melden",
        reportBtn: "Falschen Positiv melden",
        reportComment: "Kommentar eingeben",
        reportCommentPlaceholder: "Kommentar hier eingeben",
        spellingInfoTooltip:
          "Die Rechtschreibung einiger Wörter könnte falsch sein. Bitte prüfen Sie die Richtigkeit dieser Wörter oder melden Sie Falschmeldungen, indem Sie auf das Fahnensymbol klicken.",
        inaudibleInfoTooltip:
          "Dieser Teil wurde als unhörbar markiert. Verwenden Sie diese Markierung nur, wenn die Sprache unklar ist.",
        grammarInfoTooltip:
          "Es wurde ein Grammatikfehler gefunden. Überprüfen Sie die Groß- und Kleinschreibung des Satzes.",
        cleanReadInfoTooltip:
          "Es wurden einige Verstöße gegen die Clean-Read-Richtlinien gefunden. Erwägen Sie die Entfernung von Wortwiederholungen, unzulässigen Tags und umgangssprachlichen Wörtern.",
        formattingInfoTooltip:
          "Es wurden einige Verstöße gegen die Richtlinien für Untertitel festgestellt. Erwägen Sie eine Anpassung an die Limits für CPL/CPS, das Entfernen unzulässiger Symbole und die Verwendung kürzerer Klauseln.",
      },
    },
    CREATE_SUBTITLES: {
      title:
        "Ermöglichen Sie unserem Algorithmus, seine Bestleistungen zu erfüllen",
      title1:
        "Wir nutzen maschinelles Lernen, um Untertitel und Audiospur in einem lesbaren Format synchron auszurichten.",
      title2:
        "Dank dieser Automatisierung werden Sie garantiert weniger Zeit mit der Ausrichtung und Formatierung von Untertiteln verlieren.",
      title3:
        "Um Untertitel so fehlerfrei wie möglich zu erstellen, können Sie Ihr Transkript zunächst auf Fehler korrigieren.",
      note1:
        "Alle Untertiteldateien in anderen Sprachen wie Englisch, Deutsch und Niederländisch und mit einer Dauer von mehr als 10 Minuten sind zunächst der Abteilung Operations zur Anpassung vorzulegen!",
      note2:
        "Mit der Umwandlung dieser Datei in Untertitel ist Ihre Transkription nicht mehr abrufbar. Sollten Sie diese Transkription jedoch in Zukunft benötigen, exportieren Sie sie jetzt.",
      note3:
        "Die Datei wird vorübergehend aus der Ansicht der aktuellen Aufträge ausgeblendet und erscheint erst wieder, wenn sie vollständig konvertiert ist.",
      note4:
        "Es kann einige Minuten dauern, bis unser Algorithmus Ihre Untertitel fertig formatiert hat.",
      note5: "Sie werden benachrichtigt, sobald sie fertiggestellt sind.",
      confirmation1: "IIch bin mit der Durchsicht meines Transkripts fertig.",
      rule1:
        "Maximal { maxLinesPerSegment } Zeile, { maxCharsPerLine } Zeichen pro Zeile und { maxDisplayTimePerLine } Sekunden Bildschirmzeit",
      rule2: "Maximale Anzahl an Zeilen",
      rule3: "Maximale Zeichen pro Zeile (ZPL)",
      rule5: "Maximale Bildschirmzeit pro Zeile",
      customRules: "Benutzerdefinierte Regeln",
      customRulesDescription:
        "CPL, CPS, Min/Max Dauer, Zeilen pro Untertitel, Frameabstand, Zählung",
      includeSpacesInCount: "Leerzeichen in die Zeichenzählung einbeziehen",
      includeSpecialCharactersInCount:
        "Sonderzeichen (#,&,%..) in die Zeichenzählung einbeziehen",
      maxCharsPerSecond: "Maximale Zeichen pro Sekunde",
      minSubtitleDuration: "Minimale Untertiteldauer",
      maxSubtitleDuration: "Maximale Untertiteldauer",
      minFrameGap: "Minimaler Bildabstand",
      netflixRules: "Netflix-Regeln",
      msgSuccess1:
        "Der Ladevorgang hat erfolgreich begonnen, Sie werden nun zur Plattform zurückgeleitet",
      msgSuccess2:
        "Der Ladevorgang hat erfolgreich begonnen, Sie werden nun zum Dashboard zurückgeleitet",
      error1: "Es ist ein Fehler aufgetreten, bitte versuchen Sie es erneut.",
      button1: "Untertitel erstellen",
    },
    OriginalText: {
      title: "Original anzeigen",
      transcript: "Transkript",
    },
    SPELLCHECK: {
      title: "Wie kann ich die Rechtschreibprüfung aktivieren?",
      clickHere: "Klicken Sie hier",
      linkDesc:
        "für eine vollständige Anleitung, wie man die Rechtschreibprüfung in Chrome aktiviert",
      step1: "Gehe zu den Chrome Einstellungen",
      step2: "Gehe auf erweiterte Einstellungen",
      step3: "Klicke auf Sprachen",
      step4: "Öffne diesen Link in einem neuen Chrome Tab",
      step5: "Füge unter dem Menüpunkt Sprachen die gewünschte Sprache hinzu",
      step6: "Starte Chrome neu, um die Änderungen vorzunehmen",
    },
    LOGIN: {
      title: "Willkommen zurück",
      subtitle: "Im Account einloggen",
      alreadyQuestion: "Neu?",
      alreadyAction: "Jetzt anmelden.",
      btn: "Login",
      emailOrUsername: "E-Mail oder Benutzername",
      loginFormDescription:
        "Sie sind ausgeloggt oder nicht autorisiert diese Seite zu sehen. Bitte loggen Sie sich mit dem richtigen Account ein.",
      errorLogin: "Ungültige Kombination von E-Mail und Passwort",
      forgotPassword: "Passwort vergessen?",
      loggedinActionBtn: "Meine Aufnahmen",
    },
    PASSWORDRESET: {
      alreadyQuestion: "Benötigen Sie Hilfe?",
      alreadyAction: "Kontaktieren Sie uns über",
      actionLogin: "Login",
      actionRegister: "Login",
      actionOr: "oder",
      loggedinActionBtn: "Meine Aufnahmen",
    },
    PASSWORDRECOVER: {
      title: "Passwort vergessen?",
      subtitle: "Neues Passwort erstellen.",
      btn: "Passwort zurücksetzen",
      hintUsername:
        "Das ist der Benutzername, den Sie für Ihren Amberscript Account bestimmt haben.",
      or: "oder",
      hintEmail:
        "Das ist die E-Mail-Adresse, die für Ihren Amberscript Account angegeben haben.",
    },
    PASSWORDCHANGE: {
      title: "Passwort ändern",
      subtitle:
        "Neues Passwort bestimmen, um sich wieder bei Amberscript einzuloggen.",
      btn: "Mein Passwort bestätigen",
    },
    EMAILVALIDATE: {
      loading: "Bestätigung wird bearbeitet...",
      inviteTitle: "Vielen Dank für die Registrierung.",
      inviteSubtitle:
        "Eine E-Mail zur Aktivierung Ihres Kontos wurde verschickt an",
      successTitle: "Erledigt!",
      successSubtitle:
        "Danke, dass Sie Ihre E-mail bestätigt haben. Ihr Benutzerkonto ist nun aktiviert!",
      errorTitle: "Dieser Link ist nicht richtig.",
      errorSubtitle:
        "Sollte das Problem weiterhin bestehen, kontaktieren Si eins bitte.",
      loggedinActionBtn: "Meine Aufnahmen",
    },
    PLAN: {
      hour: "Stunde",
      select: "Auswählen",
      unsubscribeBtn: "Abbestellen",
      unsubscribeMsg:
        "Sind Sie sicher, dass Sie sich von Ihrem Tarif {{plan}} abmelden möchten?",
      unsubscribeOk: "Fortfahren",
      unsubscribeCancel: "Abbrechen",
      yourCurrent: "Ihr aktueller Tarif",
      vatDisclaimer: "Preise ohne MwSt",
      loading: "Tarife werden geladen...",
      noResults: "Derzeit sind keine Tarife verfügbar",
      showPlans: "Zeige Tarife",
    },
    RECORDS: {
      upload: "Hochladen",
      name: "Name",
      words: "Wörter",
      length: "Länge",
      owner: "Besitzer",
      lastModified: "Letzte Änderung",
      created: "Erstellt\r",
      more: "mehr",
      ariaMore: "Werkzeuge öffnen",
      share: "Teilen",
      delete: "Löschen",
      deleting: "Wird gelöscht...",
      deletedSuccess: "{{ recordId }} erfolgreich gelöscht.",
      slicedFileMsg:
        "Diese Audiodatei wurde wegen unzureichendem Guthaben aufgeteilt.",
      invalid: "ungültige Datei",
      errorGeneric: "Diese Transkription scheint beschädigt zu sein.",
      loading: "Ihre werden Ambers geladen...",
      noResults: "Noch keine Ambers erstellt",
      tipUploading: "Wird hochgeladen…",
      tipOpen: "Vorbereitung zum Transkribieren.",
      tipBusy: "Transkription läuft...",
      tipDone: "Bereit",
      tipError: "Beschädigt",
      scheduled:
        "{{audioName}} wird für die Transkription vorbereitet. Dies kann einige Minuten dauern.",
      transcribing: "Transkribiert {{audioName}}, {{progress}}...",
      transcribed: "Transkription von {{audioName}} ist fertig.",
      uploadErrorServer:
        "Serverfehler Fehler beim Hochladen von {{audioName}}.",
      uploadError: "{{audioName}} konnte nicht hochgeladen werden.",
      uploadProgress: "{{audioName}} wird hochgeladen",
      uploadStart: "{{audioName}} wird jetzt hochgeladen.",
      uploaded: "{{audioName}} ist jetzt hochgeladen.",
      uploadhelpTitle: "Hochladen auf Amberscript",
      uploadhelpTxt: "Amberscript unterstützt die folgenden Formate:",
      uploadUnsupprtedFormatTitle: "Dieses Format wird nicht unterstützt",
      uploadUnsupprtedFormatMsg:
        "Sie müssen eine Audiodatei auswählen. Folgende Formate werden akzeptiert:",
      uploadUnsupprtedFormatBtn: "Verstanden",
      uploadConfirmTitle:
        "Nur Audiodatei in der holländischen Sprache können zur Zeit transkribiert werden",
      uploadConfirmMsgTop:
        "Hinweis: Unsere Transkriptionen sind nur so gut, wie die Audiodateien, die Sie uns zur Verfügung stellen! Folgenden Aspekte könnten die Transkriptionsqualität verschlechtern:",
      uploadconfirmList1: "Geräusche im Hintergrund",
      uploadconfirmList2: "Starke Akzente",
      uploadconfirmList3: "Aufnahme zu leise",
      uploadConfirmMsgBottom:
        "Wenn ein oder mehrere dieser Aspekte zutreffen, kann Amberscript keine hohe Qualität der Transkriptionen gewährleisten.",
      uploadconfirmSelectASR: "Select transcription language",
      uploadConfirmOk: "Fortfahren",
      uploadConfirmCancel: "Abbrechen",
      unsupportedUploadTitle: "Kann nicht hochgeladen werden",
      unsupportedUploadMsg:
        "Es tut uns leid, aber Ihr Browser unterstützt das Hochladen der Audiodateien nicht.",
      unsupportedUploadBtn: "Verstanden",
      deleteMsg:
        "Sind Sie sicher, dass Sie die Transkription {{name}} löschen möchten?",
      deleteOk: "Bestätigung",
      deleteCancel: "Abbrechen",
      noCreditTitle: "Aktualisieren Sie Ihren Tarif",
      noCreditMsg:
        "Sie haben nicht genügend Guthaben. Um mit dem Hochladen der Audiodateien fortzufahren, abonnieren Sie einen Tarif.",
    },
    REGISTER: {
      title: "GRATIS Testversion einlösen",
      subtitle: "30 Minuten GRATIS Audio- oder Videodateien transkribieren.",
      alreadyQuestion: "Haben Sie einen Account?",
      alreadyAction: "Login",
      btn: "Registrieren",
      forgotPassword: "Passwort vergessen?",
      createPwd: "Passwort auswählen",
      confirmPwd: "Passwort bestätigen",
      loggedinActionBtn: "Meine Aufnahmen",
    },
    SHORTCUTS: {
      playPause: "Abspielen oder Pausieren der Audiodatei",
      rewind: "Aufnahme um 5 Sek. zurückspulen",
      forward: "Aufnahme um 5 Sek. vorspulen",
      skip: "Nächstes Wort",
      reverse: "Vorheriges Wort",
      highlight: "Text hervorheben",
      undo: "Rückgängig",
      redo: "Wiederherstellen",
      altClick: "Springe Audio zum Cursor",
    },
    STATUS: {
      actionBtn: "Vollen Tarif bestellen",
      remainingMsg: "Transkriptionsguthaben: {{balance}}",
    },
    UPLOADS: {
      id: "ID",
      owner: "Besitzer",
      length: "Länge",
      date: "Datum",
      noId: "Keine ID angegeben",
      loading: "Datei hochladen...\r",
      noResults: "Bisher keine Uploads",
    },
    TOUR: {
      back: "Zurück",
      close: "Schließen",
      last: "Danke!",
      next: "Weiter",
      skip: "Überspringen",
      dismissBtn: "Nicht wieder anzeigen",
      EDITOR: {
        beginTitle: "Willkommen",
        begin:
          "In diesem Tutorial zeigen wir Ihnen, wie Sie Amberscript am besten nutzen können",
        altClick:
          "Audio vorspulen indem Sie die Alt-Taste, während Sie auf ein bestimmtes Wort klicken, drücken",
        play: "Mit Play spielen Sie die Audiodatei ab. Drücken Sie Tastenkombination ctrl+ Leertaste zum Stoppen oder Weiter",
        rewind:
          "Mit diesem Button wird das Audio automatisch um 5 Sekunden zurückgespult. Die Tastenkombination ist ctrl +  r",
        highlight:
          "Mit diesem Button werden in der Zeitleiste Highlights hinzugefügt. Wählen Sie einen Teil des Textes aus und sie sehen ein markiertes Highlight in der Zeitleiste",
        shortcuts:
          "Mit diesem Button gelangen Sie zu einer Liste von verschiedenen Tastenkombinationen, die Ihnen die Arbeit mit Amberscript erleichtern",
        startTime:
          "Sie können die Zeitstempel von jedem Absatz mit der original Quelle, zum Beispiel einem Video, synchronisieren, indem sie hier klicken",
        end: "Das war’s! Haben Sie noch Fragen? Kontaktieren Sie uns jederzeit. <a href='mailto:info@amberscript.de'>info@amberscript.de</a>",
      },
    },
    REFERRAL: {
      title: "Hilf deinen Freunden Zeit zu sparen",
      description:
        "Lade deine Freunde ein Amberscript zu nutzen - für jede Person die sich anmeldet, erhälst du {signupCreditMinutes} Minuten gratis Guthaben.",
      terms: "AGB’s gelten",
      enterEmail: "E-Mail-Adresse eingeben",
      sendButton: "Einladungen verschicken",
      invitationSent:
        "Deine Einladung wurde verschickt. Du kannst gerne noch mehr Freunde einladen!",
      shareInviteLink: "Einladungs-Link teilen",
      transcriptionCredit: "Dein Transkriptionsguthaben",
      pending: "Ausstehend",
      earned: "Erhalten",
      copy: "Kopieren",
      copied: "Kopiert!",
      errorTitle: "Der Einladungscode ist abgelaufen",
      errorDescription:
        "Wir informieren Sie über neue Kampagnen per E-Mail oder Social Media.",
    },
    REALIGN: {
      btnName: "Zeitstempel neu anpassen",
      dialogTitle: "Zeitstempel neu berechnen",
      dialogContent:
        "Zeitstempel des Transkripts werden berechnet, bitte warten...",
    },
    TRANSACTIONS: {
      id: "ID",
      owner: "Besitzer",
      seconds: "Sekunden",
      date: "Datum",
      noId: "Keine ID festgelegt",
      loading: "Transaktionen werden geladen...",
      noResults: "Bisher keine Transaktionen",
    },
    THANKS: {
      mainMsg: "Danke!",
      summaryTitle: "Bestellungsübersicht",
    },
    SCRAMBLED_DIALOG: {
      title: "Huch! In Ihrem Dokument ist ein Fehler aufgetreten",
      description1:
        "Der Text in Ihrer Datei ist ungewollt durcheinander geraten.",
      description2:
        "Das hat zur Folge, dass Ihr Dokument in einem früheren Zustand wiederhergestellt werden muss. Ihre letzte Bearbeitung geht verloren, da die Seite neu geladen werden muss.",
      titleFeedback:
        "Bitte teilen Sie uns mit, welche Aktivitäten Sie durchgeführt haben, bevor dieser Dialog angezeigt wurde, damit wir das Problem beheben können.",
      descriptionButton: "Die Seite wird automatisch aktualisiert",
    },
    COUNTRIES: {
      AFG: "Afghanistan",
      ALA: "Åland Inseln",
      ALB: "Albanien",
      DZA: "Algerien",
      ASM: "Amerikanisch-Samoa",
      AND: "Andorra",
      AGO: "Angola",
      AIA: "Anguilla",
      ATA: "Antarktis",
      ATG: "Antigua und Barbuda",
      ARG: "Argentinien",
      ARM: "Armenien",
      ABW: "Aruba",
      AUS: "Australien",
      AUT: "Österreich",
      AZE: "Aserbaidschan",
      BHS: "Bahamas",
      BHR: "Bahrain",
      BGD: "Bangladesch",
      BRB: "Barbados",
      BLR: "Weißrussland",
      BEL: "Belgien",
      BLZ: "Belize",
      BEN: "Benin",
      BMU: "Bermuda",
      BTN: "Bhutan",
      BOL: "Bolivien",
      BES: "Bonaire, Saba und St. Eustatius",
      BIH: "Bosnien und Herzegowina",
      BWA: "Botswana",
      BVT: "Bouvet Insel",
      BRA: "Brasilien",
      IOT: "Britisches Territorium im Indischen Ozean",
      BRN: "Brunei Darussalam",
      BGR: "Bulgaria",
      BFA: "Burkina Faso",
      BDI: "Burundi",
      KHM: "Kambodscha",
      CMR: "Kamerun",
      CAN: "Kanada",
      CPV: "Kap Verde",
      CYM: "Kaimaninseln",
      CAF: "Zentralafrikanische Republik",
      TCD: "Tschad",
      CHL: "Chile",
      CHN: "China",
      CXR: "Weihnachtsinsel",
      CCK: "Kokosinseln",
      COL: "Kolumbien",
      COM: "Komoren",
      COG: "Kongo",
      COD: "Demokratische Republik Kongo",
      COK: "Cookinseln",
      CRI: "Costa Rica",
      CIV: "Elfenbeinküste",
      HRV: "Kroatien",
      CUB: "Kuba",
      CUW: "Curaçao",
      CYP: "Zypern",
      CZE: "Tschechien",
      DNK: "Dänemark",
      DJI: "Dschibuti",
      DMA: "Dominica",
      DOM: "Dominikanische Republik",
      ECU: "Ecuador",
      EGY: "Ägypten",
      SLV: "El Salvador",
      GNQ: "Äquatorialguinea",
      ERI: "Eritrea",
      EST: "Estland",
      ETH: "Äthiopien",
      FLK: "Falklandinseln (Malvinas)",
      FRO: "Färöer",
      FJI: "Fidschi",
      FIN: "Finnland",
      FRA: "Frankreich",
      GUF: "Französisch-Guayana",
      PYF: "Französisch-Polynesien",
      ATF: "Französische Süd- und Antarktisgebiete",
      GAB: "Gabun",
      GMB: "Gambia",
      GEO: "Georgien",
      DEU: "Deutschland",
      GHA: "Ghana",
      GIB: "Gibraltar",
      GRC: "Griechenland",
      GRL: "Grönland",
      GRD: "Grenada",
      GLP: "Guadeloupe",
      GUM: "Guam",
      GTM: "Guatemala",
      GGY: "Guernsey",
      GIN: "Guinea",
      GNB: "Guinea-Bissau",
      GUY: "Guyana",
      HTI: "Haiti",
      HMD: "Heard und McDonald Inseln",
      VAT: "Vatikanstadt",
      HND: "Honduras",
      HKG: "Hong Kong",
      HUN: "Ungarn",
      ISL: "Island",
      IND: "Indien",
      IDN: "Indonesien",
      IRN: "Iran",
      IRQ: "Irak",
      IRL: "Irland",
      IMN: "Isle of Man",
      ISR: "Israel",
      ITA: "Italy",
      JAM: "Jamaika",
      JPN: "Japan",
      JEY: "Jersey",
      JOR: "Jordanien",
      KAZ: "Kasachstan",
      KEN: "Kenia",
      KIR: "Kiribati",
      PRK: "Nordkorea",
      KOR: "Südkorea",
      KWT: "Kuwait",
      KGZ: "Kirgisistan",
      LAO: "Laos",
      LVA: "Lettland",
      LBN: "Libanon",
      LSO: "Lesotho",
      LBR: "Liberia",
      LBY: "Libyen",
      LIE: "Liechtenstein",
      LTU: "Litauen",
      LUX: "Luxemburg",
      MAC: "Macau",
      MKD: "Mazedonien",
      MDG: "Madagaskar",
      MWI: "Malawi",
      MYS: "Malaysia",
      MDV: "Malediven",
      MLI: "Mali",
      MLT: "Malta",
      MHL: "Marshallinseln",
      MTQ: "Martinique",
      MRT: "Mauretanien",
      MUS: "Mauritius",
      MYT: "Mayotte",
      MEX: "Mexiko",
      FSM: "Föderierte Staaten von Mikronesien",
      MDA: "Moldavien",
      MCO: "Monaco",
      MNG: "Mongolei",
      MNE: "Montenegro",
      MSR: "Montserrat",
      MAR: "Marokko",
      MOZ: "Mosambik",
      MMR: "Myanmar",
      NAM: "Namibia",
      NRU: "Nauru",
      NPL: "Nepal",
      NLD: "Niederlande",
      NCL: "Neukaledonien",
      NZL: "Neuseeland",
      NIC: "Nicaragua",
      NER: "Niger",
      NGA: "Nigeria",
      NIU: "Niue",
      NFK: "Norfolkinsel",
      MNP: "Nördliche Marianen",
      NOR: "Norwegen",
      OMN: "Oman",
      PAK: "Pakistan",
      PLW: "Palau",
      PSE: "Palästinensische Autonomiegebiete",
      PAN: "Panama",
      PNG: "Papua-Neuguinea",
      PRY: "Paraguay",
      PER: "Peru",
      PHL: "Philippinen",
      PCN: "Pitcairn",
      POL: "Polen",
      PRT: "Portugal",
      PRI: "Puerto Rico",
      QAT: "Katar",
      REU: "Réunion",
      ROU: "Rumänien",
      RUS: "Russland",
      RWA: "Rwanda",
      BLM: "Saint-Barthélemy",
      SHN: "St. Helena, Ascension und Tristan da Cunha",
      KNA: "St. Kitts und Nevis",
      LCA: "Sankt Lucia",
      MAF: "Saint-Martin",
      SPM: "Saint-Pierre und Miquelon",
      VCT: "St. Vincent und die Grenadinen",
      WSM: "Samoa",
      SMR: "San Marino",
      STP: "Sao Tomé und Principe",
      SAU: "Saudi-Arabien",
      SEN: "Senegal",
      SRB: "Serbien",
      SYC: "Seychellen",
      SLE: "Sierra Leone",
      SGP: "Singapur",
      SXM: "St. Martin (Niederländischer Teil)",
      SVK: "Slowakei",
      SVN: "Slowenien",
      SLB: "Salomonen",
      SOM: "Somalia",
      ZAF: "Südafrika",
      SGS: "GS",
      SSD: "Südsudan",
      ESP: "Spanien",
      LKA: "Sri Lanka",
      SDN: "Sudan",
      SUR: "Suriname",
      SJM: "Spitzbergen",
      SWZ: "Swasiland",
      SWE: "Schweden",
      CHE: "Schweiz",
      SYR: "Syrien",
      TWN: "Taiwan",
      TJK: "Tadschikistan",
      TZA: "Tansania",
      THA: "Thailand",
      TLS: "Osttimor",
      TGO: "Togo",
      TKL: "Tokelau",
      TON: "Tonga",
      TTO: "Trinidad und Tobago",
      TUN: "Tunesien",
      TUR: "Türkei",
      TKM: "Turkmenistan",
      TCA: "Turks- und Caicosinseln",
      TUV: "Tuvalu",
      UGA: "Uganda",
      UKR: "Ukraine",
      ARE: "Vereinigte Arabische Emirate",
      GBR: "Großbritannien",
      USA: "USA",
      UMI: "die Kleineren Amerikanischen Überseeinseln",
      URY: "Uruguay",
      UZB: "Usbekistan",
      VUT: "Vanuatu",
      VEN: "Venezuela",
      VNM: "Vietnam",
      VGB: "Jungferninseln, Briten",
      VIR: "Jungferninseln, U.S.",
      WLF: "Wallis und Futuna",
      ESH: "Westsahara",
      YEM: "Jemen",
      ZMB: "Sambia",
      ZWE: "Simbabwe",
    },
    GLOSSARY: {
      names: "Namen",
      title: "Wörterbuch",
      terms: "Begriffe",
      notFound:
        "Das Wörterbuch konnte nicht gefunden werden. Bitte erstellen Sie beim nächsten Mal ein Wörterbuch, bevor Sie Ihre Datei hochladen, um die Genauigkeit Ihres Textes zu verbessern.",
    },
    MACHINE_TRANSLATIONS: {
      direct: "Automatisch",
      perfect: "Professionell",
    },
    EDITOR_LANGUAGE: {
      "en-us": "Englisch (Vereinigte Staaten)",
      "en-uk": "Englisch (Vereinigtes Königreich)",
      nl: "Holländisch",
      de: "Deutsch",
      fr: "Französisch",
      es: "Spanisch",
      it: "Italienisch",
      pt: "Portugiesisch",
      da: "Dänisch",
      fi: "Finnisch",
      no: "Norwegisch (Bokmål)",
      sv: "Schwedisch",
      pl: "Polnisch",
      ro: "Rumänisch",
      uk: "Ukrainisch",
      ru: "Russisch",
      bg: "Bulgarisch",
      cs: "Tschechisch",
      el: "Griechisch",
      et: "Estnisch",
      hu: "Ungarisch",
      ja: "Japanisch",
      ko: "Koreanisch",
      lt: "Litauisch",
      lv: "Lettisch",
      sk: "Slowakisch",
      sl: "Slowenisch",
      tr: "Türkisch",
      zh: "Chinesisch",
    },
  },
};
