import React from "react";
import PropTypes from "prop-types";
import { Box, styled } from "@material-ui/core";

import TimestampBox from "./TimestampBox";
import { ReactComponent as FlagIcon } from "../../../assets/images/ui/flag.svg";
import UnderlineBoxWithSuggestion from "./UnderlineBoxWithSuggestion";

const FlagBox = styled(Box)(() => ({
  cursor: "pointer",
}));

export default function SpellingItem(props) {
  const { spellingItem, translate, index, handleOpenReportDialog } = props;

  const { original_text, suggestion, start_time } = spellingItem;

  const handleReport = () => {
    handleOpenReportDialog(spellingItem);
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      width="100%"
    >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        gridColumnGap={3}
      >
        <TimestampBox startTime={start_time} />
        <UnderlineBoxWithSuggestion
          testid={`pre-qc-spelling-suggestion-underline-suggestion-${index}`}
          originalText={original_text}
          suggestion={suggestion}
          translate={translate}
        />
      </Box>
      {/* Keep this box to prevent the flag icon to shrink if wrap */}
      <FlagBox
        data-testid={`pre-qc-spelling-suggestion-report-btn-${index}`}
        onClick={handleReport}
      >
        <FlagIcon />
      </FlagBox>
    </Box>
  );
}

SpellingItem.propTypes = {
  spellingItem: PropTypes.shape({
    original_text: PropTypes.string.isRequired,
    start_time: PropTypes.number.isRequired,
    suggestion: PropTypes.string,
  }).isRequired,
  translate: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  handleOpenReportDialog: PropTypes.func.isRequired,
};
