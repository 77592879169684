/**
 * Gets all the issues with the corresponding issue_type property
 * @param {object[]} preQCIssues 
 * @param {string} issueType 
 * @returns 
 */
 export function getIssuesByType(preQCIssues, issueType) {
  return preQCIssues.filter((issue) => issue.issue_type === issueType);
}

/**
 * Returns an array of issue objects containing only the important data
 * original_text, start_time, end_time
 * @param {object[]} preQCIssues
 * @param {string} issueType
 * @returns
 */
export function getIssuesDetails(preQCIssues, issueType) {
  if (preQCIssues && preQCIssues.length > 0) {
    // Isolate issues by issue type
    const issues = getIssuesByType(preQCIssues, issueType);

    // If none found, do nothing
    if (issues.length === 0) {
      return [];
    }

    const issuesDetails = [];
    issues.forEach((issue) => {
      const { original_text, start_time } = issue;
      const temp = {
        original_text,
        start_time,
      };
      issuesDetails.push(temp);
    });

    return issuesDetails;
  }
}

/**
 * Returns an array of words and an array of words with timestamps
 * For spelling issues exclusively
 * @param {object[]} spellingIssues
 * @returns object containing 2 arrays: words and wordsWithTimestamps
 */
export function getWordsArrays(spellingIssues) {
  const words = [];
  const wordsWithTimestamps = [];

  spellingIssues.forEach((issue) => {
    const { original_text } = issue;
    const temp = {
      ...issue,
    };
    wordsWithTimestamps.push(temp);
    words.push(original_text);
  });

  return { words, wordsWithTimestamps };
}

/**
 * Maps the suggestions to the corresponding words
 * @param {object[]} suggestions
 * @param {object[]} wordsWithTimestamps
 * @returns object array containing words, timestamps and suggestions
 */
export function mapSuggestionsToWords(suggestions, wordsWithTimestamps) {
  const result = [...wordsWithTimestamps];

  for (let word in suggestions) {
    // Get the first suggestion for the given word
    const suggestion = suggestions[word][0];

    // Find all the indices of the given word object
    const indices = result
      .map((item, i) => (item.original_text === word ? i : ""))
      .filter((item) => item !== "");

    // Add the suggestion to the found object
    indices.forEach((index) => {
      result[index] = {
        ...result[index],
        suggestion,
      };
    });
  }

  return result;
}
