export const fr = {
  locale: "fr",
  messages: {
    MISC: {
      formErrRequired: "Une valeur est exigée",
      formErrEmail: "L'adresse e-mail doit être valide",
      formHintYes: "Oui",
      formHintQuestion: "Utiliser {{ what }}?",
      signedInAs: "Vous êtes connecté comme",
      logout: "Se déconnecter",
      youOwner: "Vous",
      closeDialogBtn: "Fermer",
      ariaCloseDialog: "Fermer le dialogue",
      clearSearch: "Effacer la recherche",
      serverErrorDefaultToastMsg: "Erreur du serveur",
      ienoticeTitle: "Navigateur non-supporté",
      ienoticeTxt:
        "Internet Explorer n'est pas supporté pour le moment. Utilisez un autre navigateur, tels que Google Chrome ou Microsoft Edge",
      ienoticeDismiss: "Compris",
      requestFullTranscriptBodyDialog:
        "Si vous souhaitez que votre texte soit perfectionné par nos experts linguistiques, veuillez le télécharger à nouveau et choisissez le type de transcription 'parfait'.",
      requestFullTranscriptTitle: "Obtenez un texte précis à 100%.",
      requestFullTranscriptBody:
        "Demander un devis pour améliorer ma transcription par un de nos professionnels",
    },
    CONFIRM_SUBMIT: {
      title: "Êtes-vous sûr de vouloir soumettre le fichier ?",
      checkbox1:
        "J'ai vérifié les “Notes” du fichier, et mis en œuvre les exigences spécifiques du client si elles ont été demandées.",
      checkbox2:
        "La sortie ASR entière du fichier a été vérifiée avec le correcteur d'orthographe et de grammaire.",
      checkbox3: "La transcription a été créée conformément aux directives.",
      back: "Retour au fichier",
      submit: "Soumettre le fichier pour révision",
    },
    TRANSCRIPT_ERROR: {
      message:
        "Vous n’avez plus accès à ce dossier. Le dossier a peut-être été réassigné. Si vous avez des questions, vous pouvez contacter la team opération.",
      button: "Retour au dashboard",
    },
    NAV: {
      titleAccount: "Mon Compte",
      titleAccountNew: "Ajouter un nouvel utilisateur",
      titleAccountMy: "Mon compte",
      titleBilling: "Détail de facturation",
      titleInvoices: "Factures",
      titleCheckout: "Paiement",
      titleCoupon: "Ajouter un bon de réduction",
      titleInvite: "Inviter des ami(e)s",
      titleLogin: "Se connecter",
      titlePasswordReset: "Réinitialiser le mot de passe",
      titleEmailvalidate: "Valider l'adresse e-mail",
      titlePlan: "Tarifs",
      titleCosts: "Prix",
      titleRecords: "Tes Ambers",
      titleRecordsTooltip: "Retour au dashboard",
      titleRegister: "S'inscrire",
      titleTransactions: "Transactions",
      titleUploads: "Téléchargements",
      titleDashboard: "Tableau de bord",
      titleManageUsers: "Gérer les utilisateurs",
      titleAddUser: "Ajouter un utilisateur",
      titleManageTranscriptions: "Gérer les transcriptions",
      tooltipAdmin: "Administrateur",
      titleAdminUser: "Gérer les utilisateurs",
      tooltipLanguage: "Changer de langue",
      newBadge: "Nouveau",
      help: "Aide",
      currentPlanFallback: "Essayez gratuitement",
      currentPlanTooltip: "Forfait actuel",
    },
    LANGUAGES: {
      en: "Anglais",
      nl: "Néerlandais",
      de: "Allemand",
      fr: "Français",
      es: "Espagnol",
      it: "Italien",
      pt: "Portugais",
    },
    ACCOUNT: {
      titleForm: "Détails du compte",
      username: "Nom d'utilisateur",
      email: "E-mail",
      firstName: "Prénom",
      lastName: "Nom",
      company: "Entreprise",
      pwd: "Mot de passe",
      newPwd: "Nouveau mot de passe",
      confirmPwd: "Confirmer le mot de passe",
      changePwd: "Changer le mot de passe",
      createPwd: "Créer un mot de passe",
      msgUsernameMaxlength:
        "Le nom d'utilisateur doit comporter moins {{ quantity }} caractères.",
      msgPwdLength:
        "Le mot de passe doit comporter au moins {{ quantity }} caractères.",
      msgPwdPattern:
        "Le mot de passe doit contenir au moins un nombre, une majuscule, une minuscule et un caractère spécial",
      msgPwdConfirm: "Le nouveau mot de passe doit être confirmé",
      msgPwdMismatch: "Cela doit correspondre à l'entrée précédente",
      loading: "Chargement des détails",
      update: "Mise à jour des données",
      updating: "Sauvegarde des données...",
      save: "Sauvegarder l'utilisateur",
      saving: "Sauvegarde de l'utilisateur...",
    },
    ADMIN: {
      add: {
        donateSeconds: "Donner des secondes",
      },
      dashboard: {
        loading: "Chargement du tableau de bord...",
        noResults: "Aucun élément du tableau de bord n'est disponible.",
      },
      transactions: {
        id: "ID",
        owner: "Propriétaire",
        seconds: "Secondes",
        date: "Date",
        noId: "Aucun ID indiqué",
        loading: "Chargement des transactions...",
        noResults: "Aucune transaction à afficher",
        results: "Transactions récupérées:",
      },
      transcriptions: {
        created: "Créé",
        id: "ID",
        name: "Nom",
        user: "Utilisateur",
        status: "Statut",
        length: "Longueur",
        type: "Type",
        loading: "Chargement des transcriptions...",
        noResults: "Aucune transcription à afficher",
        results: "Transcriptions récupérées:",
      },
      users: {
        created: "Créé",
        username: "Nom d'utilisateur",
        fullname: "Nom complet",
        status: "Statut",
        minutes: "Temps d'audio",
        pause: "Suspendre l'utilisateur",
        activate: "Activer l'utilisateur",
        delete: "Supprimer l'utilisateur",
        subscribed: "Abonné",
        loading: "Chargement des utilisateurs...",
        noResults: "Aucun utilisateur à afficher",
        results: "Utilisateurs récupérés:",
        deleteConfirm: "Êtes-vous sûr de supprimer '{{ name }}'?",
        deleteOk: "Oui",
        deleteCancel: "Annuler",
        search: "Rechercher des utilisateurs",
      },
      user: {
        navBackToUsers: "Tous les utilisateurs",
        navEdit: "Profils",
        navTranscriptions: "Transcriptions",
        navTransactions: "Transactions",
        navDonate: "Faire un don",
        fullName: "Nom complet",
        availableSeconds: "Temps disponible",
        created: "Créé sur",
        isSubscribed: "Abonnement actuel",
        isntSubscribed: "Pas d'abonnement",
        status: "Statut",
        userType: "Type",
        language: "Langue préférée",
        makeAdmin: "Capacités de l'Admin",
        cancelSubscription: "Annuler l'abonnement",
        cancellingSubscription: "Annulation en cours...",
      },
      donate: {
        title: "Faire un don de crédit",
        username: "Nom d'utilisateur du destinataire",
        seconds: "Nombre de secondes",
        secondsFormatted: "Heures : Minutes : Secondes",
        comment: "Message au destinataire",
        submit: "Envoyer",
        submitting: "Envoi en cours...",
      },
      sendvalidationemail: {
        submit: "Envoyer un email de validation",
        submitting: "Valider",
      },
    },
    BILLING: {
      titleForm: "Détails de facturation",
      firstName: "Prénom",
      lastName: "Nom",
      email: "E-mail",
      phone: "Numéro de téléphone",
      company: "Entreprise",
      vatNumber: "Numéro de TVA",
      address: "Adresse",
      address2: "Adresse 2",
      city: "Ville",
      country: "Pays",
      zipCode: "Code postal",
      state: "Région",
      loading: "Chargement des détails...",
      save: "Sauvegarde des détails",
      saving: "Sauvegarde des détails en cours...",
    },
    CHECKOUT: {
      choose: "1. Choisissez un produit",
      setup: "2. Paiement",
      confirm: "3. Confirmation",
      loadingStripe: "Chargement de Stripe...",
      titleCard: "Détails",
      titleBilling: "Adresse de facturation",
      titleSummary: "Récapitulatif de la commande",
      subscribe: "Souscrire à un abonnement",
      subscribing: "Souscription en cours",
      info: "Amberscript accepte les cartes de crédit et de débit suivantes. Les paiements sont gérés en toute sécurité par Stripe.",
      labelNameOnCard: "Nom du titulaire de la carte",
      labelCard: "Carte de crédit ou de débit",
      termsDisclaimer:
        "En continuant, vous acceptez les conditions d'utilisation d'Amberscript",
      termsDisclaimerLinkTxt: "Conditions d'utilisation",
    },
    SUMMARY: {
      plan: "Tarifs:",
      cost: "Prix:",
      beyond: "Au-delà",
      hrs: "heure",
      hour: "heure",
      billingCycle: "Plan de facturation:",
      billingInterval: "mensuel",
      total: "Total:",
      loading: "Chargement du résumé",
      error: "Echec de chargement du résumé:(",
    },
    COSTS: {
      titlePlan: "Forfait actuel",
      titleCosts: "Résumé",
      titleInvoices: "Liste des factures",
      noResultsPlan: "Essai gratuit",
      noResultsCosts: "Vous n'avez aucun frais pour ce mois",
      noResultsInvoices: "Il n'y a pas encore de factures.",
      loadingSummary: "Chargement du résumé...",
      loadingInvoices: "Chargement des factures...",
      summary:
        "Ce mois-ci tu as utilisé {{ time }} pour un coût total de {{ cost }} euro.",
      remainder:
        "Avec ton choix de produit {{ planName }} tu payes {{ price }} par heure",
      remainderB: "Tu as {{ num }} heures gratuites incluses",
      remainderC1: "et un coût fixe de {{ cost }} par {{ interval }}.",
      remainderC2: "et pas de coûts fixes.",
      INVOICES: {
        invoiceNr: "Nr",
        created: "Créé",
        description: "Description",
        monthPeriod: "Mois",
        yearPeriod: "Année",
        nettoAmount: "Net",
        vatAmount: "TVA",
      },
    },
    COUPON: {
      title: "Ajouter un bon de réduction",
      inputLabel: "Code promo",
      submit: "Valider",
      submitting: "Chargement...",
      cancel: "Annuler",
      invalidCouponCode: "Code promo invalide",
    },
    EDITOR: {
      tooManyRows: "Trop de lignes",
      busy: "Chargement ...",
      feedbackBtn: "Donnez-nous votre avis",
      shortcutsBtn: "Afficher tous les raccourcis",
      shortcutsHint:
        "Pour mettre l'audio en lecture ou pause : Utilise la touche Ctrl + Espace",
      shortcutHintAltClick:
        "Vous voulez que l'audio joue la partie du texte que vous avez sélectionnée? Appuyez sur Alt",
      shortcutHintNewLine:
        "Vous voulez ajouter une nouvelle ligne dans un segment ? Appuyez sur shift + enter",
      shortcutsTitle: "Raccourcis clavier",
      ariaShortcutsClose: "Fermer les raccourcis",
      replayTour: "Replay du tutoriel",
      errorEmptySegments:
        "Amber n'a reconnu aucun mot dans la langue sélectionnée. Assurez-vous que d'avoir sélectionné la langue de l'audio et que vous avez suffisamment de crédit. N'hésitez pas à nous contacter si vous avez des questions !",
      errorLoadAudio: "Erreur lors de la tentative de chargement du fichier",
      errorLoadXML: "Erreur lors de la tentative de chargement du fichier",
      errorReadAudio: "Erreur lors de la lecture du fichier audio",
      loadingAudio: "Chargement du fichier audio...",
      loading: "Chargement de la transcription...",
      parsing: "Analyse de la transcription...",
      speakerClear: "Supprimer l'orateur'",
      speakerAddBtn: "Ajouter",
      speakerAddTitle: "Ajouter un orateur",
      speakerEditTitle: "Éditer les orateurs",
      findreplTitle: "Trouver et remplacer",
      findreplFindLabel: "Trouver:",
      findreplReplLabel: "Remplacer par:",
      findreplReplBtn: "Tout remplacer",
      infoTitle: "Info de la transcription",
      infoClose: "Fermer les infos de la transcription",
      infoNameLabel: "Nom",
      infoDateLabel: "Date",
      infoCancel: "Annuler",
      infoSave: "Sauvegarder",
      infoSaving: "Sauvegarde en cours...",
      feedbackTitle: "Avis",
      feedbackRatingLabel:
        "Comment avez-vous trouvé la qualité de la transcription?",
      feedbackCommentLabel: "Laissez un commentaire",
      feedbackSubmit: "Envoyer",
      feedbackSubmitting: "Envoi en cours...",
      feedbackVal1: "Mauvais",
      feedbackVal2: "Moyen",
      feedbackVal3: "OK",
      feedbackVal4: "Bien",
      feedbackVal5: "Très bien",
      exportBtn: "Exporter",
      exportXML: "XML",
      exportWord: "Word",
      autosaveSuccess: "Tous les changements ont été sauvegardés",
      autosavePending: "Sauvegarde en cours...",
      autoSaveSaved: "Sauvegardé",
      autoSaveError: "Une erreur s'est produite lors de la sauvegarde",
      autoSavetryAgain: "Cliquez ici pour réessayer",
      metaCreated: "Créé",
      metaLength: "Longueur",
      metaStartTimeOffset: "Début",
      metaStartTimeOffsetLabel: "Le début commence à (hh:mm:ss:ms)",
      metaStartTimeOffsetTitle: "Redéfinir le début du minutage",
      metaStartTimeOffsetDescription:
        "Redéfinir le début du minutage pour synchroniser avec la source d'origine. Inscrivez le temps qui devra être ajouté à tous les horodatages de la transcription",
      metaStartTimeOffsetSubmit: "Redéfinir le minutage",
      metaWords: "Mots",
      metaPlaybackRate: "Vitesse",
      metaPlaybackRateTip: "Changer la vitesse de lecture",
      controlsTranslation: "Ajouter des traductions",
      addNewLanguage: "AJouter une nouvelle langue",
      controlsSpellCheck: "Activer le correcteur orthographique",
      controlsSpellCheckShortcut: "(ctrl+g)",
      controlsReplace: "Remplacer",
      controlsReplaceShortcut: "(ctrl+f)",
      controlsUndo: "Annuler",
      controlsUndoShortcut: "(ctrl+z)",
      controlsRedo: "Rejouer",
      controlsRedoShortcut: "(ctrl+shift+z)",
      controlsHighlight: "Surligner",
      controlsHighlightShortcut: "(ctrl+h)",
      controlsSubtitles: "Sous-titres",
      controlsSubtitlesShortcut: "Convertir en sous-titres",
      rewindBtn: "Rembobiner",
      resumeBtn: "Reprendre",
      ariaPlay: "Play",
      ariaPause: "Pause",
      blockTextLength: "{textLength} caractères",
      adjustStartTimestampHeader: "Ajuster le début de l'horodatage",
      adjustEndTimestampHeader: "Ajuster la fin de l'horodatage",
      adjustDialogSubmit: "Ajuster",
      adjustDialogCancel: "Annuler",
      adjustTimestampDescription:
        "Le minimum et le maximum de l'horodatage sont determiné par le précédent' et les horodatages des prochains mots.",
      subtitlesDialogHeader: "Convertir en sous-titres",
      subtitlesDialogDescription:
        "Convertir automatiquement votre transcription en sous-titres. Amberscript va séparer votre texte en petite phrase qui s'adapte à l'écran.",
      subtitlesDialogNote:
        "Attention: Cette action ne peut pas être annuler automatiquement. Cependant, votre texte sera toujours éditable.",
      subtitlesDialogAdvancedOptions: "Options avancées",
      subtitlesDialogSubmit: "Convertir",
      subtitlesDialogSubmitLoading: "Veuillez attendre, s'il vous plaît",
      subtitlesDialogCancel: "Annuler",
      noEditingForMobileOrTablet:
        "Actuellement, Amberscript ne supporte pas l'édition sur votre appareil. Amberscript fonctionne de la meilleure façon sur un ordinateur utilisant Google Chrome et fonctionne aussi avec Microsoft Edge, Apple Safari et Mozilla Firefox.",
      emailValidationTitle: "L'e-mail n'a pas encore été validé",
      emailValidationDescription:
        "Pour exporter ou copier votre transcription, veuillez valider votre e-mail.",
      btnAlreadyActivated: "J'ai déjà activé mon e-mail",
      btnSendActivationAgain: "Renvoyer l'e-mail de vérification",
      btnActivationSent: "E-mail de vérification envoyé!",
      alertLoggedOutTitle: "Connection nécessaire",
      alertLoggedOutDescription:
        "Vous vous êtes déconnecté dans une autre session. Veuillez-vous reconnecter pour utiliser Amberscript.",
      alertLoggedOutButton: "Se connecter",
      btnApproveOrReject: "Approuvez-le ou rejetez-le...",
      btnDetails: "Des détails",
      dialogJobFeedback: {
        btnApprove: "Approuver",
        btnReject: "Rejeter",
        title: "Approuver ou rejeter la vérification de la qualité",
        rateTranscriber:
          "Évaluez {email} sur chaque zone pour ce travail, 1 étant le plus bas, 5 étant la plus haute qualité. Besoin d'aide? Lisez les directives de contrôle de la qualité.",
        textAreaPlaceholder: "Ajouter des notes...",
        notesExplanation:
          "Requis: ajouter des notes, même lors de l'approbation, le transcripteur peut donc voir vos commentaires.",
        research: "Recherche (noms et jargon)",
        transcription: "Tekst",
        syntax: "Richtlijnen",
        grammar: "Spelling/Tikfouten/Grammatica",
        punctuation: "Interpunctie/Syntax",
        difficulty: "Niveau de difficulté",
        difficultyExplanation:
          "Ce score ne fait pas partie du calcul total du score.",
        total: "Note de transcription totale",
        tooltip: {
          transcription:
            "It is checked whether the spoken text has been correctly converted into written text. This includes the accurate reproduction of words, sentences and statements without errors or omissions, in accordance with the guidelines. It is checked whether there were any listening errors that could have led to inaccurate or incorrect interpretation of the transcriptions. (30%)",
          syntax:
            "This is where it is checked whether the transcription was carried out in accordance with the established guidelines.This includes the use of time marks for unintelligible passages, the labelling of speakers, the observance of pauses and sentence breaks as well as other guidelines that apply to the transcription. All special guidelines are evaluated especially strictly. It must be checked whether the formatting of the transcript is appropriate. This may include line spacing, font size, arrangement of text elements and other aspects of the visual presentation of the transcript. (25%)",
          grammar:
            "In this category, grammatical errors as well as spelling and typing errors in the transcript are evaluated. The aim is to ensure that the text is both grammatically correct and free of spelling errors according to guidelines. This includes checking for errors such as misspelled words, missing capitalisation and errors in the use of abbreviations. (25%)",
          punctuation:
            "It is checked whether punctuation marks such as full stops and commas have been used correctly to improve the readability and comprehensibility of the text. It also assesses whether the use of quotes is appropriate and whether other punctuation marks have been used correctly. Another aspect of this category is the correct division of long sentences. (15%)",
          research:
            "This category focuses on evaluating the research of technical terms, proper names and other specialised expressions that appear in the transcript. It is checked whether these terms have been spelt and used correctly and, if available, whether terms from a glossary have been used. (5%)",
        },
      },
      SUBTITLE: {
        sliderShorterLabel: "Plus court",
        sliderLongerLabel: "Plus long",
        sliderRecommendedLabel: "Recommandé",
        slidercharsPerSecLabel: "{charsPerSec} caractères / sec.",
      },
      EXPORT: {
        title: "Exporter votre texte",
        format: "Format",
        export: "Exporter",
        optionsTitle: "Options d'exportation",
        optionsTimestamps: "Inclure les horodatages",
        optionsSpeakers: "Inclure une distinction entre les orateurs",
        optionsIncludeHighlights: "Inclure le surlignage",
        optionsHighlights: "Exporter seulement les parties surlignées",
        maxCharsPerRow: "Nombre maximal de caractères par ligne",
        milliseconds: "Millisecondes",
        fps: "FPS",
        cancel: "Annuler",
        ok: "Exporter",
        transcribedByAmberScript:
          "Cette transcription a été généré par www.amberscript.com",
        wayOfExport: "Comment souhaitez-vous exporter votre fichier?",
        text: "Texte",
        textDesc:
          "Word Doc ou TXT.  Idéal pour les transcriptions d'interviews, les réunions.",
        subtitleDesc:
          "SRT, VTT ou UER-STL. Peut être utilisé pour les médias sociaux, Youtube, les lecteurs vidéo et autres.",
        video: "Vidéo",
        videoDesc:
          "Téléchargez la vidéo avec ou sans sous-titres inclus. Idéal pour partager des vidéos avec des sous-titres inclus pour les médias sociaux.",
        rules: "Règles",
        burnSubToVideo:
          "Inclure les sous-titres dans la vidéo - Bientôt disponible",
        subtitleRule:
          "{maxLine} lignes maximum, {maxChar} caractères par ligne",
        realignRecommendation:
          "Nous recommandons de réaligner les horodatages avant l'exportation.",
        realignRecommendationDesc:
          "Editer de votre transcription signifie que l'audio doit être réaligné pour correspondre à vos modifications.",
        realignRecommendation1: "Rapide",
        realignRecommendation1Desc:
          "Un réalignement rapide et général. Peut ne pas être exact à 100%.",
        realignRecommendation2: "Complet - Bientôt disponible",
        realignRecommendation2Desc:
          "Un réalignement complet et précis de votre audio. Cela peut prendre plusieurs minutes.",
        realignRecommendation3: "Exporter sans se réaligner",
        realignRecommendation3Desc:
          "Vos horodatages peuvent ne pas être exacts.",
        emailNotification:
          "Vous recevrez un courriel lorsque votre fichier sera prêt à être téléchargé.",
        options: "Options",
        whatIsJson:
          "JSON est un format de données qui peut être utilisé pour des logiciels.",
        audio: "Audio",
        audioDesc: "Téléchargez votre fichier.",
        downloadAudio: "Télécharger le fichier audio",
        downloadVideo: "Télécharger la vidéo",
        exportSubtitleTitle: "Exporter Sous-titres",
        custom: "Personnalisé",
        maxNrRows: "Nombre maximal de lignes",
        maxCharPerRow:
          "Nombre maximal de caractères par ligne, espaces compris",
        titleTranscript: "Exporter la transcription",
        downloadFile: "Télécharger le fichier audio",
        asAFile: "En tant que fichier",
        asAFileDesc:
          "SRT, VTT ou EBU-STL. Peut être utilisé pour les réseaux sociaux, Youtube, les lecteurs vidéo et plus encore.",
        download: "Télécharger",
        asAVideoWithBurnIn: "En tant que vidéo avec sous-titres incrustés",
        asAVideoWithBurnInDesc:
          "Les sous-titres apparaîtront sur le fichier vidéo de façon permanente. Exportation en mp4, etc.",
        createNewVersion: "Créer une nouvelle version",
        lastExport: "Dernière exportation:",
        additionalOptions: "Plus d'options",
        downloadAsText: "Télécharger en tant que texte",
        downloadAudioFile: "Télécharger le fichier audio",
        downloadVideoFile: "Télécharger le fichier vidéo",
        postVideoOnYoutube: "Publier une vidéo sur YouTube",
        intelligentExport: "Exportation intelligente",
        summary: "Résumé",
        summaryTooltip: "Générer un résumé de votre transcription",
        anonymization: "Anonymisation",
        anonymizationTooltip:
          "Anonymiser toutes les données personnelles (noms, adresses, etc.) de votre transcription.",
        cleanRead: "Lecture propre",
        cleanReadTooltip:
          "Génère une transcription éditée pour une meilleure lisibilité.",
      },
      BURN_IN: {
        processingVideo: "Traitement de la vidéo",
        confirmationTitle: "Êtes-vous satisfait de votre fichier ?",
        confirmationText:
          "Vous ne pouvez incruster qu'une seule fois la version actuelle de ces sous-titres. Si vous modifiez le fichier des sous-titres par la suite et souhaitez les réincruster, des frais supplémentaires s'appliqueront.",
        continue: "Oui, continuer",
        back: "Non, continuer à éditer",
        successTitle: "Commande réussie",
        successText1:
          "Le temps de traitement peut être long. Une fois le processus terminé, vous en serez informé et pourrez télécharger la vidéo via l'écran d'",
        successText2: "du tableau de bord ou dans l'éditeur.",
        export: "Exportation",
        returnToEditor: "Retourner à l'éditeur",
        continueToDashboard: "Aller au tableau de bord",
        notAvailableText: "Vous souhaitez créer un nouvel export ?",
        notAvailableLink: "Contacter le support client",
        notConverted:
          "Vous devez convertir votre document en sous-titres avant de procéder à l'incrustation de sous-titres.",
      },
      toast: {
        onCopy:
          "Les fonctions copier, couper et coller ne sont pas disponibles.",
        onCut:
          "Les fonctions copier, couper et coller ne sont pas disponibles.",
        onPaste:
          "Les fonctions copier, couper et coller ne sont pas disponibles.",
      },
      RPT: {
        title: "Laissez-nous faire le montage pour vous",
        description:
          "En demandant le service de transcription manuelle, nos experts linguistiques auront accès à votre fichier pour le corriger. Les modifications apportées à votre fichier actuel sont réinitialisées et votre dossier sera verrouillé jusqu'à ce que les corrections soient terminées.",
        description2: "Cliquez sur OK pour procéder à la vérification",
      },
      BANNER: {
        firstStepTitle: "Vérifiez et corrigez votre transcription.",
        firstStepDescTranscriber:
          "Vous pouvez sauter cette étape, mais il peut y avoir des erreurs dans vos sous-titres.",
        firstStepDesc:
          "Vous pouvez ignorer cette étape, mais il peut y avoir des erreurs dans vos sous-titres. Vous pouvez également demander une révision à l’un de nos experts.",
        secondStepTitle:
          "Vous avez terminé de modifier vos sous-titres ? Cliquez sur Créer des sous-titres pour les aligner et les formater.",
        secondStepDesc:
          "Vous pourrez également enregistrer la transcription pour vos dossiers.",
      },
      PRE_QC: {
        submitBtn: "Envoyer au Contrôle Qualité...",
        dialogTitle: "Assistant Qualité",
        submitAnyway: "Envoyer quand même",
        viewFix: "Voir & Corriger",
        spelling: " fautes d'orthographe",
        grammar: " fautes de grammaire",
        cleanread: " fautes de clean-read",
        inaudible: " marquages inaudibles",
        cancel: "Annuler",
        submit: "Envoyer",
        confirmationTitle: "Envoyer pour contrôle",
        confirmation:
          "Vous êtes sur le point d'envoyer le fichier au contrôle qualité. Cette action est irréversible. Êtes-vous certain de vouloir continuer?",
        emptyState: "Recevez une vérification de votre document rapidement.",
        runPreQCBtn: "Lancer l'Assistant Qualité",
        spellingIssueFound: " faute d'orthographe trouvée",
        spellingIssuesFound: " fautes d'orthographe trouvées",
        grammarIssueFound: " faute de grammaire trouvée",
        grammarIssuesFound: " fautes de grammaire trouvées",
        cleanreadIssueFound: " faute de clean read trouvée",
        cleanreadIssuesFound: " fautes de clean read trouvées",
        inaudibleIssueFound: " marquage inaudible trouvé",
        inaudibleIssuesFound: " marquage inaudible trouvé",
        noSuggestion: "Pas de suggestion",
        loadingSuggestions: "chargement des suggestions...",
        noIssueNotice: "Aucune erreur n’a été trouvée, bien joué !",
        formatting: " fautes de format",
        spellingInfoTooltip:
          "L'orthographe de certains mots peut être incorrecte. Pensez à les corriger ou signalez les faux positifs en cliquant sur l'icône du drapeau.",
        inaudibleInfoTooltip:
          "Cette section a été marquée comme inaudible. Veillez à n'utiliser cette balise que lorsque le discours n'est pas clair.",
        grammarInfoTooltip:
          "Une faute de grammaire a été trouvée. Pensez à vérifier les majuscules en début de phrase.",
        cleanReadInfoTooltip:
          "Certaines violations des lignes directrices relatives à la lecture propre ont été constatées. Envisagez de supprimer les mots répétés, les balises non autorisées et les mots d'argot.",
        formattingInfoTooltip:
          "Quelques violations des lignes directrices sur les sous-titres ont été constatées. Envisager d'ajuster les limites CPL/CPS, de supprimer les symboles non autorisés et d'avoir des clauses plus courtes.",
      },
    },
    CREATE_SUBTITLES: {
      title: "Aidez notre algorithme à faire son meilleur travail",
      title1:
        "Nous utilisons l’apprentissage automatique pour aligner les sous-titres sur l’audio dans un format lisible.",
      title2:
        "Grâce à cette automatisation, vous passez moins de temps à corriger l’alignement et le formatage des sous-titres.",
      title3:
        "La correction des erreurs dans votre transcription avant la création des sous-titres les rend encore plus précis !",
      note1:
        "Tous les fichiers de sous-titres dans des langues autres que l’anglais, l’allemand et le néerlandais, d’une durée supérieure à 10 minutes, doivent d’abord être soumis pour alignement aux Opérations !",
      note2:
        "Une fois que vous aurez converti ce fichier en sous-titres, votre transcription ne sera plus disponible. Veuillez exporter la transcription maintenant si vous en avez besoin plus tard.",
      note3:
        "Le fichier va disparaître temporairement de l’onglet des jobs en cours, et réapparaîtra une fois qu’il aura été entièrement converti.",
      note4:
        "Il faudra quelques minutes à notre algorithme pour formater vos sous-titres.",
      note5: "Nous vous ferons savoir quand ils seront prêts.",
      confirmation1: "J’ai fini de corriger ma transcription",
      rule1:
        "Maximum {maxLinesPerSegment} ligne, {maxCharsPerLine} caractères par ligne, et {maxDisplayTimePerLine} secondes de temps d’écran",
      rule2: "Nombre maximum de lignes",
      rule3: "Caractères maximum par ligne (CPL)",
      rule5: "Durée maximale d’affichage par ligne",
      customRules: "Règles personnalisées",
      customRulesDescription:
        "CPL, CPS, Min/Max duration, Lines per subtitle, Frame gap, Counting",
      includeSpacesInCount:
        "Inclure les espaces dans le décompte des caractères",
      includeSpecialCharactersInCount:
        "Inclure les caractères spéciaux (#,&,%...) dans le décompte des caractères",
      maxCharsPerSecond: "Nombre maximal de caractères par seconde",
      minSubtitleDuration: "Durée minimale des sous-titres",
      maxSubtitleDuration: "Durée maximale des sous-titres",
      minFrameGap: "Écart minimal entre les images",
      netflixRules: "Netflix rules",
      msgSuccess1:
        "Le processus a démarré avec succès, vous allez être redirigé vers la plateforme",
      msgSuccess2:
        "Le processus a démarré avec succès, vous allez être redirigé vers le tableau de bord",
      error1: "Une erreur inconnue s’est produite, veuillez réessayer",
      button1: "Créer des sous-titres",
    },
    OriginalText: {
      title: "Show Original",
      transcript: "Transcript",
    },
    OriginalText: {
      title: "Afficher l'original",
      transcript: "Transcription",
    },
    SPELLCHECK: {
      title: "Comment activer le correcteur orthographique?",
      clickHere: "Cliquez ici",
      linkDesc:
        "Pour obtenir les instructions complètes sur la manière d'activer le correcteur d'orthographe dans Chrome",
      step1: "Accédez aux paramètres de Chrome",
      step2: "Cliquez sur la section Avancé",
      step3: "Cliquez sur Langues",
      step4: "Ouvrez ce lien dans un nouvel onglet Chrome",
      step5: "Dans la section des langues, ajoutez la langue souhaitée",
      step6: "Redémarrez Chrome pour appliquer les modifications",
    },
    LOGIN: {
      title: "Bon retour parmi nous",
      subtitle: "Connectez-vous à votre compte",
      alreadyQuestion: "Nouveau?",
      alreadyAction: "S'inscrire maintenant",
      btn: "S'identifier",
      emailOrUsername: "E-mail ou nom d'utilisateur",
      loginFormDescription:
        "Vous êtes déconnecté ou non autorisé à voir cette page. Veuillez vous reconnecter avec le bon compte.",
      errorLogin:
        "Combinaison entre l'adresse e-mail et le mot de passe non valide",
      forgotPassword: "Mot de passe oublié?",
      loggedinActionBtn: "Accéder à mes dossiers",
    },
    PASSWORDRESET: {
      alreadyQuestion: "Besoin d'aide?",
      alreadyAction: "Contactez-nous à",
      actionLogin: "S'identifier",
      actionRegister: "S'inscrire",
      actionOr: "ou",
      loggedinActionBtn: "Accéder à mes dossiers",
    },
    PASSWORDRECOVER: {
      title: "Mot de passe oublié?",
      subtitle: "Crée-en un nouveau ici.",
      btn: "Réinitialiser mon mot de passe",
      hintUsername:
        "C'est le nom d'utilisateur que vous avez utilisé pour créer votre compte Amberscript.",
      or: "ou",
      hintEmail:
        "C'est l'email que vous avez utilisé pour créer votre compte Amberscript.",
    },
    PASSWORDCHANGE: {
      title: "Changer de mot de passe",
      subtitle:
        "Créer un nouveau mot de passe pour vous reconnecter à Amberscript",
      btn: "Confirmer mon mot de passe",
    },
    EMAILVALIDATE: {
      loading: "Validation en cours...",
      inviteTitle: "Merci de votre inscription.",
      inviteSubtitle: "Un e-mail pour activer votre compte a été envoyé à",
      successTitle: "Terminé!",
      successSubtitle:
        "Merci d’avoir validé votre email. Votre compte est maintenant actif.",
      errorTitle: "Ce lien de validation n'est pas correct.",
      errorSubtitle: "Si le problème persiste contacte-nous au",
      loggedinActionBtn: "Accéder à mes fichiers",
    },
    PLAN: {
      hour: "heure",
      select: "Sélectionner",
      unsubscribeBtn: "Se désabonner",
      unsubscribeMsg:
        "Êtes-vous sûr de vouloir vous désabonner de votre {{ plan }}?",
      unsubscribeOk: "Procéder",
      unsubscribeCancel: "Annuler",
      yourCurrent: "Votre abonnement actuel",
      vatDisclaimer: "Prix excl. TVA.",
      loading: "Chargement des options...",
      noResults: "Aucun abonnement disponible pour le moment",
      showPlans: "Afficher les tarifs",
    },
    RECORDS: {
      upload: "Télécharger",
      name: "Nom",
      words: "Mots",
      length: "Longueur",
      owner: "Propriétaire",
      lastModified: "Dernière modification",
      created: "Créé",
      more: "Plus",
      ariaMore: "Ouvrir le menu",
      share: "Partager",
      delete: "Supprimer",
      deleting: "Suppression en cours...",
      deletedSuccess: "{{ recordId }} Supprimé avec succès.",
      slicedFileMsg:
        "Ce fichier audio/vidéo a été découpé car vous n'avez pas assez de crédit.",
      invalid: "Fichier non valide",
      errorGeneric:
        "Cette transcription semble contenir une ou plusieurs erreurs",
      loading: "Chargement de vos ambers...",
      noResults: "Aucun ambers créé pour le moment",
      tipUploading: "Téléchargement...",
      tipOpen: "Transcription en attente",
      tipBusy: "Transcription en cours...",
      tipDone: "Prêt",
      tipError: "Erreur",
      scheduled:
        "{{ audioName }} est en attente de transcription. Soyez patient, vous recevrez un email lorsque la transcription sera prête!",
      transcribing: "Transcription {{ audioName }}, {{ progress }}...",
      transcribed: "Transcription de {{ audioName }} est prête.",
      uploadErrorServer:
        "Erreur du serveur. Echec du téléchargement {{ audioName }}.",
      uploadError: "Le téléchargement de {{ audioName }} a échoué.",
      uploadProgress: "Téléchargement {{ audioName }}:",
      uploadStart: "A commencé à télécharger {{ audioName }}.",
      uploaded: "Téléchargement de {{ audioName }} est terminé.",
      uploadhelpTitle: "Téléchargement sur Amberscript",
      uploadhelpTxt:
        "Amberscript prend en charge les formats audios/vidéos suivants:",
      uploadUnsupprtedFormatTitle: "Format non supporté",
      uploadUnsupprtedFormatMsg:
        "Vous devez sélectionner un fichier audio ou vidéo. Formats acceptés :",
      uploadUnsupprtedFormatBtn: "Compris",
      uploadConfirmTitle: "Prêt pour la transcription!",
      uploadConfirmMsgTop:
        "Remarque: assurez-vous que la qualité de votre fichier audio/vidéo est suffisante. Nous prenons en charge les fichiers qui respectent ces critères :",
      uploadconfirmList1: "Contient un discours clairement prononcé",
      uploadconfirmList2: "N'a pas de bruit de fond",
      uploadconfirmList3: "Ne contient pas d'orateur avec un fort accent",
      uploadConfirmMsgBottom:
        "Si l'un de ces critères n'est pas respecté, Amberscript ne peut pas garantir une transcription de haute qualité.",
      uploadconfirmSelectASR: "Choisir la langue",
      uploadConfirmOk: "Valider",
      uploadConfirmCancel: "Annuler",
      unsupportedUploadTitle: "Téléchargement non pris en charge",
      unsupportedUploadMsg:
        "Nous sommes désolés mais ton navigateur ne supporte pas le téléchargement de fichiers audios/vidéos.",
      unsupportedUploadBtn: "Compris",
      deleteMsg: "Êtes-vous sûr de supprimer '{{ name }}'?",
      deleteOk: "Confirmer",
      deleteCancel: "Annuler",
      noCreditTitle: "Acheter des crédits",
      noCreditMsg:
        "Vous avez un nombre de crédits insuffisants. Pour continuer à télécharger des fichiers audios/vidéos, ajoutez des crédits.",
    },
    REGISTER: {
      title: "Demandez votre ESSAI GRATUIT",
      subtitle: "Obtenez 30 minutes de transcription audio ou vidéo GRATUITES",
      alreadyQuestion: "Vous avez déjà un compte?",
      alreadyAction: "Se connecter",
      btn: "S'inscire",
      forgotPassword: "Mot de passe oublié?",
      createPwd: "Choisissez un mot de passe",
      confirmPwd: "Confirmez votre mot de passe",
      loggedinActionBtn: "Accéder à mes fichiers",
    },
    SHORTCUTS: {
      playPause: "Lire et mettre en pause votre fichier audio/vidéo",
      rewind: "Rembobinage de votre audio de 5 secondes",
      forward: "Avance rapide de votre audio de 5 secondes",
      skip: "Sauter un mot",
      reverse: "Inverser un mot",
      highlight: "Surligner le texte",
      undo: "Annuler",
      redo: "Refaire",
      altClick:
        "Mettre votre audio en avance rapide sur le passage sélectionné",
    },
    STATUS: {
      actionBtn: "Acheter des crédits",
      remainingMsg: "Mode essai: {{ balance }} restant",
    },
    UPLOADS: {
      id: "ID",
      owner: "Propriétaire",
      length: "Longueur",
      date: "Date",
      noId: "Aucun ID spécifié",
      loading: "Chargement des téléchargements...",
      noResults: "Aucun téléchargement pour le moment",
    },
    TOUR: {
      back: "Retour",
      close: "Fermer",
      last: "Merci!",
      next: "Suivant",
      skip: "Passer",
      dismissBtn: "Ne plus afficher cela à nouveau",
      EDITOR: {
        beginTitle: "Bienvenue",
        begin: "Nous allons vous montrer comment utiliser Amberscript.",
        altClick:
          "Mettre votre audio en avance rapide en appuyant sur alt tout en cliquant sur un mot",
        play: "En appuyant sur Play, vous jouer votre audio. Vous pouvez facilement arrêter et continuer en appuyant sur ctrl + Espace",
        rewind:
          "Avec ce bouton, vous rembobinez de 5 secondes, le raccourci est Ctrl + r",
        highlight:
          "Avec ce bouton vous pouvez facilement ajouter des points importants dans la chronologie de votre audio. Sélectionnez une partie de votre transcription et elle sera représentée dans la chronologie de l'audio",
        shortcuts:
          "Ce bouton permet d'afficher des raccourcis utiles pour que vous puissiez travailler sur Amberscript plus rapidement",
        startTime:
          "Vous pouvez ajuster le début de l'horodatage de chaque paragraphe pour être synchronisé avec la source audio en cliquant ici.",
        end: "Voilà! Des questions ? N'hésitez pas à nous contacter <a href='mailto:info@amberscript.com'>info@amberscript.com</a>",
      },
    },
    REFERRAL: {
      title: "Aidez vos amis à gagner du temps",
      description:
        "Invitez vos amis à utiliser Amberscript - pour chaque personne qui s'inscrit, vous disposez d'un crédit allant jusqu'à {{signupCreditMinutes}} minutes.",
      terms: "conditions d'utilisation",
      enterEmail: "Entrez l'adresse email",
      sendButton: "Envoyer les invitations",
      invitationSent:
        "Votre invitation a été envoyée. N'hésitez pas à inviter plus d'amis !",
      shareInviteLink: "Partager le lien d'invitation",
      transcriptionCredit: "Votre crédit de transcription",
      pending: "En attente",
      earned: "Crédits obtenus",
      copy: "Copier",
      copied: "Copié!",
      errorTitle: "Le code d'invitation est expiré",
      errorDescription:
        "Nous vous informerons des nouvelles campagnes par email ou via les réseaux sociaux.",
    },
    REALIGN: {
      btnName: "Réaligner les horodatages",
      dialogTitle: "Alignement en cours",
      dialogContent:
        "Amélioration de l'horodatage de la transcription, veuillez patienter...",
    },
    TRANSACTIONS: {
      id: "ID",
      owner: "Propriétaire",
      seconds: "Secondes",
      date: "Date",
      noId: "Aucun ID spécifié",
      loading: "Chargement des transactions...",
      noResults: "Aucune transaction pour le moment",
    },
    THANKS: {
      mainMsg: "Merci!",
      summaryTitle: "Récapitulatif de la commande",
    },
    SCRAMBLED_DIALOG: {
      title: "Oups! Une erreur s’est produite dans votre document.",
      description1:
        "Le texte de votre document s’est accidentellement brouillé.",
      description2:
        "En conséquence, votre document doit être restauré à un état antérieur. Votre dernière modification sera perdue car la page doit être rafraîchie.",
      titleFeedback:
        "Veuillez nous indiquer les actions que vous avez entreprises avant l’apparition de cette boîte de dialogue afin que nous puissions remédier à ce problème.",
      descriptionButton: "La page sera rafraîchie automatiquement",
    },
    COUNTRIES: {
      AFG: "Afghanistan",
      ALA: "Îles Aland ",
      ALB: "Albanie",
      DZA: "Algérie",
      ASM: " Samoa Américaine",
      AND: "Andorre",
      AGO: "Angola",
      AIA: "Anguilla",
      ATA: "Antarctique",
      ATG: "Antigua et Barbuda",
      ARG: "Argentine",
      ARM: "Arménie",
      ABW: "Aruba",
      AUS: "Australie",
      AUT: "Autriche",
      AZE: "Azerbaïdjan",
      BHS: "Bahamas",
      BHR: "Bahreïn",
      BGD: "Bangladesh",
      BRB: "La Barbade",
      BLR: "Bélarus",
      BEL: "Belgique",
      BLZ: "Belize",
      BEN: "Bénin",
      BMU: "Bermudes",
      BTN: "Bhoutan",
      BOL: "Bolivie, État plurinational",
      BES: "Bonaire, Saint-Eustache et Saba",
      BIH: "Bosnie Herzégovine",
      BWA: "Botswana",
      BVT: "Île Bouvet",
      BRA: "Brésil",
      IOT: "Territoire britannique de l'océan Indien",
      BRN: "Brunei Darussalam",
      BGR: "Bulgarie",
      BFA: "Burkina Faso",
      BDI: "Burundi",
      KHM: "Cambodge",
      CMR: "Cameroun",
      CAN: "Canada",
      CPV: "Cap Vert",
      CYM: "Îles Caïmans",
      CAF: "République centrafricaine",
      TCD: "Tchad",
      CHL: "Chili",
      CHN: "Chine",
      CXR: "L'île de noël",
      CCK: "Îles Cocos (Keeling)",
      COL: "Colombie",
      COM: "Comores",
      COG: "Congo",
      COD: "Congo, République démocratique",
      COK: "les Îles Cook",
      CRI: "Costa Rica",
      CIV: "Côte d'Ivoire",
      HRV: "Croatie",
      CUB: "Cuba",
      CUW: "Curacao",
      CYP: "Cyprus",
      CZE: "République Tchèque",
      DNK: "Danemark",
      DJI: "Djibouti",
      DMA: "Dominique",
      DOM: "République Dominicaine",
      ECU: "Equateur",
      EGY: "Egypte",
      SLV: "Le Salvador",
      GNQ: "Guinée Équatoriale",
      ERI: "Érythrée",
      EST: "Estonie",
      ETH: "Ethiopie",
      FLK: "Îles Falkland (Malvinas)",
      FRO: "Îles Féroé",
      FJI: "Fidji",
      FIN: "Finlande",
      FRA: "France",
      GUF: "Guinée Française",
      PYF: "Polynésie française",
      ATF: "Terres australes françaises",
      GAB: "Gabon",
      GMB: "Gambie",
      GEO: "Géorgie",
      DEU: "Allemagne",
      GHA: "Ghana",
      GIB: "Gibraltar",
      GRC: "Grèce",
      GRL: "Groenland",
      GRD: "Grenade",
      GLP: "Guadeloupe",
      GUM: "Guam",
      GTM: "Guatemala",
      GGY: "Guernesey",
      GIN: "Guinée",
      GNB: "Guinée-Bissau",
      GUY: "Guyane",
      HTI: "Haiti",
      HMD: "Île Heard et îles McDonald",
      VAT: "Saint-Siège (État de la Cité du Vatican)",
      HND: "Honduras",
      HKG: "Hong Kong",
      HUN: "Hongrie",
      ISL: "Islande",
      IND: "Inde",
      IDN: "Indonésie",
      IRN: "Iran, République Islamique",
      IRQ: "Irak",
      IRL: "Irlande",
      IMN: "Île de Man",
      ISR: "Israël",
      ITA: "Italie",
      JAM: "Jamaïque",
      JPN: "Japon",
      JEY: "Jersey",
      JOR: "Jordan",
      KAZ: "Kazakhstan",
      KEN: "Kenya",
      KIR: "Kiribati",
      PRK: "République populaire démocratique de Corée",
      KOR: "Corée, République",
      KWT: "Koweït",
      KGZ: "Kirghizistan",
      LAO: "République démocratique populaire lao",
      LVA: "Lettonie",
      LBN: "Liban",
      LSO: "Lesotho",
      LBR: "Liberia",
      LBY: "Libye",
      LIE: "Liechtenstein",
      LTU: "Lithuanie",
      LUX: "Luxembourg",
      MAC: "Macao",
      MKD: "Macédoine, l'ex-République yougoslave",
      MDG: "Madagascar",
      MWI: "Malawi",
      MYS: "Malaisie",
      MDV: "Maldives",
      MLI: "Mali",
      MLT: "Malte",
      MHL: "Îles Marshall",
      MTQ: "Martinique",
      MRT: "Mauritanie",
      MUS: "Maurice",
      MYT: "Mayotte",
      MEX: "Mexique",
      FSM: "Micronésie, États fédérés",
      MDA: "Moldova, République",
      MCO: "Monaco",
      MNG: "Mongolie",
      MNE: "Montenegro",
      MSR: "Montserrat",
      MAR: "Maroc",
      MOZ: "Mozambique",
      MMR: "Myanmar",
      NAM: "Namibie",
      NRU: "Nauru",
      NPL: "Népal",
      NLD: "Pays-Bas",
      NCL: "Nouvelle Calédonie",
      NZL: "Nouvelle-Zélande",
      NIC: "Nicaragua",
      NER: "Niger",
      NGA: "Nigeria",
      NIU: "Nioué",
      NFK: "l'île de Norfolk",
      MNP: "Îles Mariannes du Nord",
      NOR: "Norvège",
      OMN: "Oman",
      PAK: "Pakistan",
      PLW: "Palau",
      PSE: "Territoire palestinien, occupé",
      PAN: "Panama",
      PNG: "Papouasie Nouvelle Guinée",
      PRY: "Paraguay",
      PER: "Pérou",
      PHL: "Philippines",
      PCN: "Pitcairn",
      POL: "Polande",
      PRT: "Portugal",
      PRI: "Porto Rico",
      QAT: "Qatar",
      REU: "Réunion",
      ROU: "Roumanie",
      RUS: "Fédération Russe",
      RWA: "Rwanda",
      BLM: "Saint Barthélemy",
      SHN: "Sainte-Hélène, Ascension et Tristan da Cunha",
      KNA: "Saint-Christophe-et-Niévès",
      LCA: "Sainte-Lucie",
      MAF: "Saint Martin (partie française)",
      SPM: "Saint Pierre et Miquelon",
      VCT: "Saint-Vincent-et-les-Grenadines",
      WSM: "Samoa",
      SMR: "Saint Marin",
      STP: "Sao Tomé et Principe",
      SAU: "Arabie Saoudite",
      SEN: "Sénégal",
      SRB: "Serbie",
      SYC: "les Seychelles",
      SLE: "Sierra Leone",
      SGP: "Singapour",
      SXM: "Sint Maarten (partie néerlandaise)",
      SVK: "Slovaquie",
      SVN: "Slovénie",
      SLB: "Les îles Salomon",
      SOM: "Somalie",
      ZAF: "Afrique du Sud",
      SGS: "Géorgie du Sud et les îles Sandwich du Sud",
      SSD: "Soudan du sud",
      ESP: "Espagne",
      LKA: "Sri Lanka",
      SDN: "Soudan",
      SUR: "Suriname",
      SJM: "Svalbard et Jan Mayen",
      SWZ: "Swaziland",
      SWE: "Suède",
      CHE: "Suisse",
      SYR: "République arabe syrienne",
      TWN: "Taiwan, Province de Chine",
      TJK: "Tadjikistan",
      TZA: "Tanzanie, République-Unie",
      THA: "Thaïlande",
      TLS: "Timor-Leste",
      TGO: "Togo",
      TKL: "Tokelau",
      TON: "Tonga",
      TTO: "Trinité-et-Tobago",
      TUN: "Tunisie",
      TUR: "Turquie",
      TKM: "Turkménistan",
      TCA: "îles Turques-et-Caïques",
      TUV: "Tuvalu",
      UGA: "Ouganda",
      UKR: "Ukraine",
      ARE: "Emirats Arabes Unis",
      GBR: "Royaume-Uni",
      USA: "États Unis",
      UMI: "Îles mineures éloignées des États-Unis",
      URY: "Uruguay",
      UZB: "Ouzbékistan",
      VUT: "Vanuatu",
      VEN: "Venezuela, République bolivarienne",
      VNM: "Vietnam",
      VGB: "Îles Vierges britanniques",
      VIR: "Îles Vierges, États-Unis",
      WLF: "Wallis et Futuna",
      ESH: "Sahara occidental",
      YEM: "Yémen",
      ZMB: "Zambie",
      ZWE: "Zimbabwe",
    },
    GLOSSARY: {
      title: "Dictionnaire",
      names: "Noms",
      terms: "Termes",
      notFound:
        "Le dictionnaire n'a pas été trouvé. Veuillez créer un dictionnaire la prochaine fois avant de télécharger votre fichier afin d'améliorer la précision de votre texte.",
    },
    MACHINE_TRANSLATIONS: {
      direct: "Automatique",
      perfect: "Professionnelle",
    },
    EDITOR_LANGUAGE: {
      "en-us": "Anglais (États-Unis)",
      "en-uk": "Anglais (Royaume-Uni)",
      nl: "Néerlandais",
      de: "Allemand",
      fr: "Français",
      es: "Espagnol",
      it: "Italien",
      pt: "Portugais",
      da: "Danois",
      fi: "Finnois",
      no: "Norvégien (Bokmål)",
      sv: "Suédois",
      pl: "Polonais",
      ro: "Roumain",
      uk: "Ukrainien",
      ru: "Russe",
      bg: "Bulgare",
      cs: "Tchèque",
      el: "Grecque",
      et: "Estonien",
      hu: "Hongrois",
      ja: "Japonais",
      ko: "Coréen",
      lt: "Lituanien",
      lv: "letton",
      sk: "Slovaque",
      sl: "Slovène",
      tr: "Turc",
      zh: "Chinois",
    },
  },
};
