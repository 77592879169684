import React from "react";
import PropTypes from "prop-types";
import { Box } from "@material-ui/core";

import AmberOutlinedButton from "../../../buttons/AmberOutlinedButton";
import PanelTitle from "./panelTitle";

export default function MoreOptions(props) {
  const { translate, handleDownloadAsText, handleDownloadFile, isVideo } =
    props;
    
  const openSurveyForYoutube = () => {
    window.open(
      'https://docs.google.com/forms/d/1PB32Y4CPDMeDWMdjoz7uVUkRvTGquLQ3c6yLF-SzA-0/viewform?pli=1&pli=1&edit_requested=true',
      '_blank',
    );
  }
    
  const getAdaptedMediaText = () =>
    isVideo
      ? translate("EDITOR.EXPORT.downloadVideoFile")
      : translate("EDITOR.EXPORT.downloadAudioFile");

  return (
    <Box>
      <PanelTitle
        text={translate("EDITOR.EXPORT.additionalOptions")}
        marginBottom={2}
        testid="export-subtitles-dialog-additionalOptions-title"
      />
      <Box
        display="flex"
        flexDirection="row"
        mt={1}
        alignItems="center"
        height={40}
        gridColumnGap={12}
      >
        <AmberOutlinedButton
          text={translate("EDITOR.EXPORT.postVideoOnYoutube")}
          onClick={openSurveyForYoutube}
          testid="export-subtitles-dialog-additionalOptions-postYoutubeBtn"
        />
        <AmberOutlinedButton
          text={translate("EDITOR.EXPORT.downloadAsText")}
          onClick={handleDownloadAsText}
          testid="export-subtitles-dialog-additionalOptions-downloadTextBtn"
        />
        <AmberOutlinedButton
          text={getAdaptedMediaText()}
          onClick={handleDownloadFile}
          testid="export-subtitles-dialog-additionalOptions-downloadMediaBtn"
        />
      </Box>
    </Box>
  );
}

MoreOptions.propTypes = {
  translate: PropTypes.func.isRequired, 
  handleDownloadAsText: PropTypes.func.isRequired, 
  handleDownloadFile: PropTypes.func.isRequired, 
  isVideo: PropTypes.bool.isRequired,
};
