import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box, styled } from "@material-ui/core";

import Title from "../glossary/title";
import PreQCEmptyPanel from "./PreQCEmptyPanel";
import PreQCFilledPanel from "./PreQCFilledPanel";
import { ReactComponent as ReplayLogo } from "../../../assets/images/ui/replay.svg";
import ReportSpellingDialog from "./reportFalsePositive/ReportSpellingDialog";

const CustomBoxReplay = styled(Box)(() => ({
  cursor: "pointer",
}));
export default function PreQCPanel(props) {
  const {
    preQCIssues,
    translate,
    runPreQC,
    loadingQC,
    setLoadingQC,
    QCHasBeenRun,
    language,
    jobId,
    userName,
    roles,
  } = props;

  const [openReportDialog, setOpenReportDialog] = useState(false);
  const [falsePositive, setFalsePositive] = useState(null);

  const handleOpenReportDialog = (issue) => {
    setFalsePositive(issue);
    setOpenReportDialog(true);
  };

  const handleCloseReportDialog = () => {
    setOpenReportDialog(false);
  };

  const hasPreQCIssues = () => preQCIssues && preQCIssues.length > 0 && !loadingQC;

  const handleClickReplay = async () => {
    setLoadingQC(true);
    try {
      await runPreQC(false, false);
    } catch (error) {
    } finally {
      if (loadingQC) {
        setLoadingQC(false);
      }
    }
  };

  const shouldShowReplay = () => hasPreQCIssues() && !loadingQC;

  function Replay() {
    return (
      <CustomBoxReplay
        onClick={handleClickReplay}
        data-testid="pre-qc-button-replay-qc"
      >
        <ReplayLogo />
      </CustomBoxReplay>
    );
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100vh"
      width="100vw"
      data-testid="pre-qc-box-panel"
    >
      {falsePositive && (
        <ReportSpellingDialog
          open={openReportDialog}
          translate={translate}
          handleClose={handleCloseReportDialog}
          issue={falsePositive}
          language={language}
          jobId={jobId}
          userName={userName}
          roles={roles}
        />
      )}

      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        paddingLeft={2.75}
        paddingRight={2.75}
        height={54}
        borderBottom="1px solid #D0D0D0"
      >
        <Title text={translate("EDITOR.PRE_QC.dialogTitle")} />
        {shouldShowReplay() && <Replay />}
      </Box>
      {hasPreQCIssues() ? (
        <PreQCFilledPanel
          preQCIssues={preQCIssues}
          language={language}
          translate={translate}
          setLoadingQC={setLoadingQC}
          handleOpenReportDialog={handleOpenReportDialog}
        />
      ) : (
        <PreQCEmptyPanel
          translate={translate}
          runPreQC={runPreQC}
          preQCIssues={preQCIssues}
          QCHasBeenRun={QCHasBeenRun}
          loadingQC={loadingQC}
        />
      )}
    </Box>
  );
}

PreQCPanel.propTypes = {
  loadingQC: PropTypes.bool.isRequired,
  setLoadingQC: PropTypes.func.isRequired,
  QCHasBeenRun: PropTypes.bool.isRequired,
  preQCIssues: PropTypes.arrayOf(
    PropTypes.shape({
      end_time: PropTypes.number.isRequired,
      end_word_index: PropTypes.number.isRequired,
      issue_severity: PropTypes.string.isRequired,
      issue_type: PropTypes.string.isRequired,
      issue_type_detail: PropTypes.string,
      original_text: PropTypes.string.isRequired,
      segment_index: PropTypes.number.isRequired,
      start_time: PropTypes.number.isRequired,
      start_word_index: PropTypes.number.isRequired,
      suggestion: PropTypes.string.isRequired,
    })
  ),
  translate: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  jobId: PropTypes.string,
  userName: PropTypes.string.isRequired,
  roles: PropTypes.arrayOf(PropTypes.string.isRequired),
};

PreQCPanel.defaultProps = {
  preQCIssues: null,
  jobId: null,
};
